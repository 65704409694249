import { IonAlert, IonButton, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonList, IonRow } from '@ionic/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getConfig } from '../../appConfig';
import Basket from '../../lib/basket';
import { withTranslation } from '../../lib/translate';
import { deepCopy, forwardTo, isDefined, isEmptyObject, makeKey } from '../../lib/utils';
import { getIkentooMenusForLocation, removeVoucher, setCommonProp, setModal } from '../../store/actions';
import { NormalText, SmallText, Spacer, StrongText, Subtitle, Title } from '../common';
import { closeOutline } from 'ionicons/icons';
import Incrementer from '../incrementer';
import Modal from '../modal';
import './index.css';
import { GET_TAX } from '../../store/constants';

class OrderContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			serviceChargeModalOpen: false,
			showCharge: false,
			serviceChargeAmount: null,
			serviceChargeBoxModalOpen: false,
			removeItem: {},
			showRemoveItemAlert: false,
			removeItemAlertFunction: () => null,
			removeServiceChargeModal: false
		};
	}

	handleServiceChargeModal = (flag) => {
		this.setState({ serviceChargeModalOpen: flag });
		// if (isWebConfig()) {
		//   this.setState({ serviceChargeModalOpen: flag, showCharge: !flag });
		// } else {
		//   forwardTo('/service-charge');
		// }
	};

	drawSubItems = (basketItem = {} /*, basketItemIndex*/) => {
		const { selectedChoices } = basketItem;
		const basketInstance = this.props.basketInstance || Basket;
		const { profile } = this.props;
		if (selectedChoices && selectedChoices.length > 0) {
			return selectedChoices.map((choiceGroup, choiceGroupIndex) => {
				return (choiceGroup || []).map((choice, choiceIndex) => {
					const { sku } = choice;
					return (
						<IonRow className="sub-item" key={makeKey(choiceGroupIndex, choiceIndex, sku)} /*onClick={ () => this.handleOrderItemClick(basketItem, basketItemIndex) }*/>
							<IonCol size="50px"></IonCol>
							<IonCol className="paddLR grow">
								{choice.quantity} x {Basket.getProductName(choice, profile)}
							</IonCol>
							<IonCol className="righted paddLR self-aligned">
								{basketInstance.calculateSubItemPrice(choice, choice.quantity) !== 0 ? basketInstance._calculateSubItemPrice(choice, choice.quantity) : null}
							</IonCol>
						</IonRow>
					);
				});
			});
		}
		return null;
	};
	drawSubItemsText = (basketItem = {} /*, basketItemIndex*/) => {
		const { selectedChoices } = basketItem;
		const basketInstance = this.props.basketInstance || Basket;
		const { profile } = this.props;
		if (selectedChoices && selectedChoices.length > 0) {
			return selectedChoices.map((choiceGroup, choiceGroupIndex) => {
				return (choiceGroup || []).map((choice, choiceIndex) => {
					const { sku } = choice;
					return (
						<div className="sub-item modifier-item" key={makeKey(choiceGroupIndex, choiceIndex, sku)} /*onClick={ () => this.handleOrderItemClick(basketItem, basketItemIndex) }*/>
							<NormalText className="paddLR grow">
								<SmallText>
									{choice.quantity} x {Basket.getProductName(choice, profile)}
								</SmallText>
							</NormalText>
							<NormalText className="righted paddLR self-aligned">
								<SmallText>{basketInstance.calculateSubItemPrice(choice, choice.quantity) !== 0 ? basketInstance._calculateSubItemPrice(choice, choice.quantity) : null}</SmallText>
							</NormalText>
						</div>
					);
				});
			});
		}
		return null;
	};
	selectServicePercentage(serviceCharge) {
		const basketInstance = this.props.basketInstance || Basket;
		const serviceChargeApplied = basketInstance.getServicePercentage();
		return serviceCharge === serviceChargeApplied;
	}
	drawServiceChargeBox = (__, service_charge, basketInstance) => {
		return (
			<IonList className="service-charge-box-wrapper box-wrapper centered">
				<div className='service-charge-title'>
					{!getConfig().serviceChargeBoxHidden && <StrongText>{__('Add a service charge?')}</StrongText>}
					<StrongText>{basketInstance.getServiceChargeValue() > 0 ? basketInstance.getFormatedServiceChargeValue() : basketInstance.getServiceCharge(true)}</StrongText>
				</div>
				<div className="service-charge-values">
					{service_charge.map((item) => {
						return (
							<IonButton
								className={this.selectServicePercentage(item) && 'is-active'}
								key={item + 'service_charge'}
								expand="block"
								color="primary"
								onClick={(e) => {
									e.stopPropagation();
									document.querySelectorAll('.service-charge-values ion-button').forEach((el) => el.classList.remove('is-active'));
									e.target.classList.add('is-active');
									this.selectServicePercentage(item) ? basketInstance.setServicePercentage(0) : basketInstance.setServicePercentage(item)
								}}
							>
								{item === 0 ? <NormalText>{__('None')} </NormalText> : <NormalText>{item}% </NormalText>}
							</IonButton>
						);
					})}
				</div>

				<IonButton onClick={() => this.setState({ serviceChargeModalOpen: true, serviceChargeBoxModalOpen: false })} fill="clear" className="link underlined bold">
					{__('Add different amount')}
				</IonButton>
				{/* <IonButton

					onClick={() => {
						basketInstance.setServicePercentage(0);
						this.setState({ serviceChargeBoxModalOpen: false });
					}}
					fill="clear"
					color='primary'
					className="link underlined block decline-service-charge-btn"
				>
					{__('Not this time')}
				</IonButton> */}
			</IonList>
		);
	};
	setCustomServiceCharge = (value) => {
		if (value >= 0) {
			this.setState({ serviceChargeAmount: value, customAmountErrorVisible: false });
		} else {
			this.setState({ customAmountErrorVisible: true, serviceChargeAmount: 0 });
		}
	};
	getOrderStatusColor = (val) => {
		const status = val.toUpperCase();
		let color = '';
		if (status === 'PREPARING' || status === 'ONWAY') {
			color = 'warning';
		} else if (status === 'COLLECTED' || status === 'READY' || status === 'DELIVERED') {
			color = 'success';
		} else if (status === 'FAILED' || status === 'PAYMENT FAILED') {
			color = 'danger';
		} else {
			color = 'gray';
		}
		return color;
	};
	selectMultiVenueRestaurant = (restaurant) => {
		Basket.setRestaurant(restaurant);
		if (Basket.order_type == 'collection') {
			const pickTime = moment.unix(Basket.collection_time).tz(getConfig().timezone).format('HH:mm');
			this.setState({ selectRestaurantsModalOpen: false }, () => {
				this.props.dispatch(
					getIkentooMenusForLocation(restaurant.business_location_id, {
						pickTime,
						json_time_selector: restaurant ? restaurant.json_time_selector : [],
					}),
				);
			});
		} else if (Basket.order_type == 'table') {
			this.setState({ selectRestaurantsModalOpen: false }, () => {
				this.props.dispatch(
					getIkentooMenusForLocation(restaurant.business_location_id, {
						pickTime: 'asap',
						json_time_selector: restaurant ? restaurant.table_json_time_selector : [],
					}),
				);
			});
		} else if (Basket.order_type == 'pick-up-at-counter') {
			this.setState({ selectRestaurantsModalOpen: false }, () => {
				this.props.dispatch(
					getIkentooMenusForLocation(
						restaurant.business_location_id,
						{
							pickTime: 'asap',
							json_time_selector: restaurant ? restaurant.pick_up_at_counter_json_time_selector : [],
						},
						false,
					),
				);
			});
		} else if (Basket.order_type == 'browse-menu') {
			this.setState({ selectRestaurantsModalOpen: false }, () => {
				this.props.dispatch(
					getIkentooMenusForLocation(
						restaurant.business_location_id,
						{
							pickTime: 'asap',
							json_time_selector: restaurant ? restaurant.browse_menu_json_time_selector : [],
						},
						restaurant.browse_menu_json_time_selector.length === 1 ? false : true,
					),
				);
			});
		} else if (Basket.order_type == 'charter-delivery') {
			const pickTime = moment.unix(Basket.collection_time).tz(getConfig().timezone).format('HH:mm');
			this.setState({ selectRestaurantsModalOpen: false }, () => {
				this.props.dispatch(
					getIkentooMenusForLocation(restaurant.business_location_id, {
						pickTime,
						json_time_selector: restaurant ? restaurant.charter_delivery_times_json : [],
					}),
				);
			});
		} else if (Basket.order_type == 'scheduled-delivery') {
			const pickTime = moment.unix(Basket.collection_time).tz(getConfig().timezone).format('HH:mm');
			this.setState({ selectRestaurantsModalOpen: false }, () => {
				this.props.dispatch(
					getIkentooMenusForLocation(restaurant.business_location_id, {
						pickTime,
						json_time_selector: [
							{
								menuId: restaurant?.active_menus.scheduled_delivery?.menu_id,
								availability: Object.keys(restaurant?.active_menus.scheduled_delivery?.slots).map((slot) => {
									if (restaurant?.active_menus.scheduled_delivery?.slots[slot].start_time) {
										return {
											d: weekdays[slot],
											start: restaurant?.active_menus.scheduled_delivery?.slots[slot].start_time,
											end: restaurant?.active_menus.scheduled_delivery?.slots[slot].end_time,
										};
									}
								}),
							},
						],
					}),
				);
			});
		} else if (Basket.order_type == 'scheduled-collection') {
			const pickTime = moment.unix(Basket.collection_time).tz(getConfig().timezone).format('HH:mm');
			this.setState({ selectRestaurantsModalOpen: false }, () => {
				this.props.dispatch(
					getIkentooMenusForLocation(restaurant.business_location_id, {
						pickTime,
						json_time_selector: [
							{
								menuId: restaurant?.active_menus.scheduled_collection?.menu_id,
								availability: Object.keys(restaurant?.active_menus.scheduled_collection?.slots).map((slot) => {
									if (restaurant?.active_menus.scheduled_collection?.slots[slot].start_time) {
										return {
											d: weekdays[slot],
											start: restaurant?.active_menus.scheduled_collection?.slots[slot].start_time,
											end: restaurant?.active_menus.scheduled_collection?.slots[slot].end_time,
										};
									}
								}),
							},
						],
					}),
				);
			});
		}
	};
	finaliseTableOrder = () => {
		this.props.dispatch(setCommonProp('serviceChargeBoxAlertOpen', false));

		let data = Basket.has_multi_basket
			? deepCopy(Basket.multi_basket_items)
			: [
				{
					restaurant: { business_location_id: Basket.getRestaurant()?.business_location_id },
					items: Basket.items,
				},
			];
		const additionalData = Basket.getAdditionalTaxData();
		if (additionalData) {
			data.push(additionalData);
		}
		this.props.dispatch({ type: GET_TAX, data, includeAll: true });
	};
	render() {
		const { handleOrderItemClick, __, history, profile, showAddItems, dispatch, isRemoveVoucherModalOpen, taxContentLoading } = this.props;
		const { showRemoveItemAlert, removeItemAlertFunction } = this.state;
		const basketInstance = this.props.basketInstance || Basket;
		const type = this.props.type || 'order';
		const orderType = basketInstance.getOrderType();
		const appliedPoints = basketInstance.getAppliedPoints();
		const deliveryPrice = basketInstance.getDeliveryPrice();
		const deliveryPriceUnformated = basketInstance._getDeliveryPrice();
		const { serviceChargeModalOpen, removeItem } = this.state;
		const appliedVoucher = basketInstance.getAppliedVoucher();
		const appliedGiftCards = basketInstance.getAppliedGiftCards();

		let location = history && history.location && history.location.pathname === '/history-details' ? true : false;
		let service_charge_original;
		let service_charge = [];
		if (!basketInstance.service_charge_value && basketInstance.service_charge_value !== 0 && getConfig().general.service_charge_modules?.includes(Basket.order_type)) {
			if (basketInstance.getRestaurantServiceCharge()) {
				service_charge_original = basketInstance.getRestaurantServiceCharge();
			} else {
				service_charge_original =
					typeof getConfig().general.defaultServiceCharge == 'string' ? JSON.stringify(getConfig().general.defaultServiceCharge) : getConfig().general.defaultServiceCharge;
			}
			service_charge = service_charge_original.map((el) => parseFloat(el * 100));
		}
		const isAlreadyCreatedOrder = type === 'orderHistory';

		return (
			<>

				{basketInstance.has_multi_basket && !basketInstance.is_gift ? (
					<>
						{basketInstance.multi_basket_items?.map((order) => (
							<>
								<StrongText className="order-summary-items-wrapper text-to-update">{`${__('Your order')} ${order.restaurant.name}`}</StrongText>
								{isAlreadyCreatedOrder && (
									<div className={`restautant-order-status restautant-order-status-${this.getOrderStatusColor(order.status)}`}>
										<NormalText>{__(order.status)}</NormalText>
									</div>
								)}
								<div
									className={`${taxContentLoading ? 'unclickable' : ''} basket-items-wrapper  ${isAlreadyCreatedOrder ? `basket-items-wrapper-${this.getOrderStatusColor(order.status)}` : ''
										} `}
								>
									{showAddItems && (
										<div className="add-items-button-wrapper">
											<IonButton
												onClick={() => this.selectMultiVenueRestaurant(order.restaurant)}
												fill="clear"
												size="small"
												color="secondary"
												className="add-items-btn link underlined"
											>
												{__('Order more')}
											</IonButton>
										</div>
									)}

									{order.items.map((basketItem, basketItemIndex) => {
										const { quantity, item, instructions } = basketItem;
										let image = item.itemRichData && item.itemRichData.squareImageUrl ? item.itemRichData.squareImageUrl : '';
										if (image && image.indexOf('http://') !== -1) {
											image = image.replace(/http:\/\//g, 'https://');
										}
										return (
											<div className="box-content">
												<div
													className="order-item-image"
													style={
														image && image !== ''
															? { backgroundImage: `url(${image})` }
															: { backgroundSize: 'contain', backgroundImage: `url(${this.props.clientProfile.logo_image})` }
													}
												/>
												<div className="item-content-wrapper flex-justify-between flex-row-wrapper">
													<div>
														<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
															<div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
																<NormalText className="primary-color">{basketInstance.getProductName(item, profile)}</NormalText>
																<NormalText>{basketInstance.formatPrice(basketInstance.calculateItemPrice(basketItem, false))}</NormalText>
															</div>
														</div>
														{this.drawSubItemsText(basketItem, basketItemIndex)}
														{basketItem.instructions !== '' && (
															<>
																<Spacer size={1} />
																<SmallText className="block">{`${__('Instructions')}: ${basketItem.instructions}`} </SmallText>
															</>
														)}
													</div>
													<div className="item-content-actions flex-justify-between flex-col-wrapper flex-align-end">
														<Incrementer
															onUpdate={(val) => {
																if (handleOrderItemClick) {
																	handleOrderItemClick(basketItem, basketItemIndex, val, order.restaurant.id);
																}
															}}
															quantity={quantity}
															step={1}
															allowNegative={false}
															minLimit={1}
														>
															<StrongText className="bold primary-color">{quantity}</StrongText>
														</Incrementer>
														<StrongText>
															{basketInstance.calculateItemPrice(basketItem, true) !== basketInstance.calculateItemPrice(basketItem, false) && (
																<>{basketInstance.formatPrice(basketInstance.calculateItemPrice(basketItem, true))}</>
															)}
														</StrongText>
													</div>
												</div>
											</div>
										);
									})}
								</div>
								<Spacer size={1} />
							</>
						))}
					</>
				) : (
					<>
						<StrongText className=" order-summary-items-wrapper text-to-update">{__('Your order')}</StrongText>

						<div className={`basket-items-wrapper ${taxContentLoading ? 'unclickable' : ''}`}>
							<div className="add-items-button-wrapper">
								{showAddItems ? (
									<IonButton fill="clear" size="small" color="primary" className="add-items-btn link underlined" onClick={() => forwardTo('/order')}>
										{__('Add more items')}
									</IonButton>
								) : null}
							</div>

							{basketInstance.getItems().map((basketItem, basketItemIndex) => {
								const { quantity, item, instructions } = basketItem;
								let image = item.itemRichData && item.itemRichData.squareImageUrl ? item.itemRichData.squareImageUrl : '';
								if (image && image.indexOf('http://') !== -1) {
									image = image.replace(/http:\/\//g, 'https://');
								}
								return (
									<div className="box-content">
										<div
											className="order-item-image"
											style={
												image && image !== ''
													? { backgroundImage: `url(${image})` }
													: { backgroundSize: 'contain', backgroundImage: `url(${this.props.clientProfile.logo_image})` }
											}
										/>
										<div className="item-content-wrapper flex-justify-between flex-row-wrapper">
											<div>
												<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
													<StrongText className="block primary-color">{Basket.getProductName(item, profile)}</StrongText>
													{handleOrderItemClick && (
														<IonIcon className='pointer' icon={closeOutline} onClick={() => { this.setState({ showRemoveItemAlert: true, removeItemAlertFunction: () => handleOrderItemClick(basketItem, basketItemIndex, 0) }) }} />
													)}
												</div>


												{this.drawSubItemsText(basketItem, basketItemIndex)}
												{basketItem.instructions && basketItem.instructions !== '' && (
													<>
														<Spacer size={1} />
														<SmallText className="block">{`${__('Instructions')}: ${basketItem.instructions}`} </SmallText>
													</>
												)}
											</div>
											<div className="item-content-actions flex-justify-between flex-col-wrapper flex-align-end">
												<Incrementer
													onUpdate={(val) => {
														if (handleOrderItemClick) {
															handleOrderItemClick(basketItem, basketItemIndex, val);
														}
													}}
													quantity={quantity}
													step={1}
													allowNegative={false}
													minLimit={1}

												>
													<StrongText className="bold primary-color">{quantity}</StrongText>
												</Incrementer>
												<StrongText>
													{basketInstance.calculateItemPriceByIndex(basketItemIndex, true) > 0 ? basketInstance._calculateItemPriceByIndex(basketItemIndex, true) : null}
												</StrongText>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</>
				)}
				<IonGrid className="box-content order-content-wrapper paddL">
					{!basketInstance.is_gift ? (
						<IonRow>
							<IonCol>
								<IonCol class="bold">{__('Items total')}</IonCol>
							</IonCol>
							<IonCol className="righted bold">{basketInstance.calculateItemsTotal()}</IonCol>
						</IonRow>
					) : null}
				</IonGrid>
				{isAlreadyCreatedOrder &&

					<IonGrid className="box-content order-content-wrapper paddL">
						<>
							{((basketInstance.service_percentage > 0 || basketInstance.service_percentage === false) &&
								getConfig().general.service_charge_modules?.includes(Basket.order_type) &&
								getConfig().general.hasServiceCharge) ||
								isAlreadyCreatedOrder &&
								basketInstance.getServiceChargeValue() > 0 ? (
								<div key="service_charge" className="basket-item-wrapper">
									<IonRow className="basket-item-service-charge-row service-charge-item" onClick={() => isAlreadyCreatedOrder ? () => { } : this.setState({ removeServiceChargeModal: true })}>
										<>
											<IonCol className="paddLR grow ">
												<StrongText>
													{__('Service charge')}&nbsp;
													{!isAlreadyCreatedOrder &&
														<>
															{basketInstance.service_charge_value ? (
																<> {basketInstance.service_percentage !== 0 && basketInstance._getServicePercentage()}</>
															) : (
																<> {basketInstance.service_percentage !== false && basketInstance._getServicePercentage()}</>
															)}
														</>}

												</StrongText>

											</IonCol>
											<IonCol className="righted paddLR self-center bold">
												{basketInstance.getServiceChargeValue() > 0 ? basketInstance.getFormatedServiceChargeValue() : basketInstance.getServiceCharge(true)}
											</IonCol>
										</>
									</IonRow>
									<Modal
										className="remove-apply-voucher"
										actionLabel={__('Yes')}
										action={() => this.setState({ removeServiceChargeModal: false }, () => {
											basketInstance.setServiceCharge(0)
											basketInstance.setServicePercentage(0)

										})}
										isOpen={this.state.removeServiceChargeModal}
										onDidDismiss={() => this.setState({ removeServiceChargeModal: false })}
										noContent={true}
									>
										<NormalText>{__('Do you want to remove service charge') + ' ?'}</NormalText>
									</Modal>
								</div>
							) : null}
							{deliveryPriceUnformated > 0 ? (
								<IonRow>
									<IonCol className="paddLR grow">
										<StrongText>
											{basketInstance.getRestaurant()?.delivery_charge_in_percent && basketInstance.geRestaurantDeliveryPrice()
												? `${basketInstance.geRestaurantDeliveryPrice()}% `
												: null}
											{__('Delivery Charge')}
										</StrongText>

									</IonCol>
									<IonCol className="righted paddLR bold">
										{/* basketInstance._calculatePointsAppliedPrice(null, true) */} {deliveryPrice}
									</IonCol>
								</IonRow>
							) : null}
							{basketInstance.process_fee_value > 0 && (
								<IonRow className="">
									<>
										<IonCol className="paddLR grow ">
											<StrongText>
												{Basket.processing_fee_description > 0 ? `${Basket.processing_fee_description}% ` : null}
												{__('Order processing fee')}
											</StrongText>

										</IonCol>
										<IonCol className="righted paddLR self-center bold ">{basketInstance.getProcessingFee()}</IonCol>
									</>
								</IonRow>
							)}

							{basketInstance.discount && (
								<IonRow>
									<IonCol className="paddLR grow">{basketInstance.discount.title}</IonCol>
									<IonCol className="righted paddLR bold">-{basketInstance.formatPrice(basketInstance.calculateMembershipDiscount())}</IonCol>
								</IonRow>
							)}

						</>
						<Modal className="service-charge-modal" isOpen={serviceChargeModalOpen} onDidDismiss={() => this.handleServiceChargeModal(false)}>
							<StrongText className='block'>{__('Add service charge')}</StrongText>
							<Spacer size={1} />
							<IonItem lines="none" className="input-field-wrapper inline-input-field-wrapper inline-input-field-wrapper-text-only">
								<div>
									<NormalText>{Basket.getCurrency().label}</NormalText>
								</div>
								<IonInput placeholder='Service charge amount' onInput={(e) => this.setCustomServiceCharge(e.target.value)} type="number" pattern="number" inputmode="number"></IonInput>
							</IonItem>

							<Spacer size={1} />
							{this.state.customAmountErrorVisible && (
								<>
									<SmallText className="danger-color">{__('Value can not be negative')}</SmallText>
									<Spacer size={1} />
								</>
							)}
							<IonButton
								expand="block"
								onClick={() => {
									basketInstance.setServiceCharge(this.state.serviceChargeAmount);
									this.setState({ serviceChargeModalOpen: false });
								}}
								disabled={!this.state.serviceChargeAmount}
							>
								{this.state.serviceChargeAmount ? (
									<>
										{__('Add')} {basketInstance.formatPrice(this.state.serviceChargeAmount)}
									</>
								) : (
									<>{__('Add')}</>
								)}
							</IonButton>
						</Modal>
					</IonGrid>
				}
				{basketInstance.getTotalTaxBreakdown() > 0 && (
					<>
						<IonGrid className={`box-content order-content-wrapper paddL ${taxContentLoading ? 'box-content--loading' : ''}`}>
							{basketInstance.getTaxDataForDisplay().map((taxLine) => (
								<div style={{ position: 'relative' }}>
									{taxContentLoading && <IonSpinner color="primary" name="bubbles"></IonSpinner>}
									<IonRow>
										<IonCol className="paddLR grow ">{taxLine.tax_name}</IonCol>
										<IonCol className="righted paddLR self-center ">{basketInstance.formatPrice(taxLine.tax_amount)}</IonCol>
									</IonRow>
								</div>
							))}
							<IonRow>
								<IonCol className="paddLR grow bold">{__('Total taxes')}</IonCol>
								<IonCol className="righted paddLR self-center bold">{basketInstance.formatPrice(basketInstance.getTotalTaxBreakdown(), true)}</IonCol>
							</IonRow>
						</IonGrid>
					</>
				)}

				{this.props.serviceChargeBoxAlertOpen && (
					<Modal backdropDismiss={false} className="service-charge-modal service-charge-modal-full" isOpen={this.props.serviceChargeBoxAlertOpen}>
						<Title>{__('Add Service Charge')}</Title>
						<Spacer size={1} />
						{this.drawServiceChargeBox(__, service_charge, basketInstance)}
						<Spacer size={1} />
						<IonButton
							expand="block"
							color="primary"
							onClick={() => {
								this.finaliseTableOrder();
							}}
						>
							{__('Add Service Charge')}
						</IonButton>
						<IonButton
							color="primary "
							onClick={() => {
								basketInstance.setServicePercentage(0);
								this.finaliseTableOrder();
							}}
							fill="clear"
							className="link underlined block"
						>
							{__('Not this time')}
						</IonButton>
					</Modal>
				)}
				<IonGrid class="box-wrapper order-content-wrapper">
					{isDefined(appliedPoints) && appliedPoints > 0 ? (
						<IonRow>
							<IonCol className="paddLR grow">
								{appliedPoints}&nbsp;{__('points redeemed')}
							</IonCol>
							<IonCol className="righted paddLR bold">{basketInstance._calculatePointsAppliedPrice(null, true)}</IonCol>
						</IonRow>
					) : null}
					{appliedGiftCards &&
						basketInstance.getAppliedGiftCardsValue() > 0 &&
						appliedGiftCards
							.filter((card) => card.value > 0)
							.map((card) => {
								return (
									<IonRow key={card.id}>
										<IonCol className="paddLR grow ">{`${__('Gift Card')} #${__(card.id)}`} </IonCol>
										<IonCol className="righted paddLR bold">{basketInstance.formatPrice(card.value * -1)}</IonCol>
									</IonRow>
								);
							})}
					{appliedVoucher &&
						appliedVoucher.length > 0 &&
						appliedVoucher.map((voucher) => {
							let voucherName = voucher.name;
							if (voucher && voucher.reward && voucher.reward.name) {
								voucherName = voucher.reward.name;
							}
							return (
								<>
									<IonRow key={voucher.id} onClick={() => (isAlreadyCreatedOrder ? null : dispatch(setModal('isRemoveVoucherModalOpen', true)))}>
										<IonCol className="paddLR grow bold">{isAlreadyCreatedOrder ? __('Loyalty voucher applied') : __(voucherName)} </IonCol>
										<IonCol className="righted paddLR bold">{basketInstance._calculateAppliedVocuhersPrice(true, null)}</IonCol>
									</IonRow>
									<Modal
										className="remove-apply-voucher"
										actionLabel={__('Yes')}
										action={() => dispatch(removeVoucher(voucher.id))}
										isOpen={!!isRemoveVoucherModalOpen}
										onDidDismiss={() => dispatch(setModal('isRemoveVoucherModalOpen', false))}
										noContent={true}
									>
										<NormalText>{__('Do you want to remove voucher') + ' ?'}</NormalText>
									</Modal>
								</>
							);
						})}

				</IonGrid>
				<IonGrid class="box-wrapper order-content-wrapper order-content-total">
					{(isAlreadyCreatedOrder || this.props.taxCalculated || !getConfig().flags.hasTaxBreakdown) && (
						<IonRow>
							<IonCol>
								<IonCol class="bold">{isAlreadyCreatedOrder ? __('Total') : __('Total to pay')}</IonCol>
							</IonCol>
							<IonCol className="righted  bold">{basketInstance._getTotal(true)}</IonCol>
						</IonRow>
					)}
				</IonGrid>
				{basketInstance.service_percentage === 0 &&
					getConfig().general.service_charge_modules?.includes(Basket.order_type) &&
					getConfig().general.hasServiceCharge &&
					getConfig().general.serviceChargeBoxHidden &&
					basketInstance.getServiceChargeValue() === null ? (
					<div key="service_charge" className="basket-item-wrapper">
						<IonButton
							expand="block"
							fill="clear"
							color="primary"
							className="link underlined basket-item-service-charge-row"
							onClick={() => this.setState({ serviceChargeBoxModalOpen: true })}
						>
							{__('add service charge')}
						</IonButton>
					</div>
				) : null}
				{getConfig().general.hasServiceCharge &&
					!basketInstance.service_charge_value &&
					basketInstance.service_charge_value !== 0 &&
					getConfig().general.service_charge_modules?.includes(Basket.order_type) && (
						<>
							{!getConfig().general.serviceChargeBoxHidden ? (
								<>{this.drawServiceChargeBox(__, service_charge, basketInstance)}</>
							) : (
								<>
									<Modal
										className="service-charge-modal service-charge-modal-full"
										isOpen={this.state.serviceChargeBoxModalOpen}
										onDidDismiss={() => this.setState({ serviceChargeBoxModalOpen: false })}
									>
										<Title>{__('Add Service Charge')}</Title>
										<Spacer size={1} />
										{this.drawServiceChargeBox(__, service_charge, basketInstance)}
										<Spacer size={1} />
										<IonButton
											expand="block"
											color="primary"
											onClick={() => {
												this.setState({ serviceChargeBoxModalOpen: false });
											}}
										>
											{__('Add Service Charge')}
										</IonButton>
										<IonButton
											color="primary "
											onClick={() => {
												basketInstance.setServicePercentage(0);
												this.setState({ serviceChargeBoxModalOpen: false });
											}}
											fill="clear"
											className="link underlined block"
										>
											{__('Not this time')}
										</IonButton>
									</Modal>
								</>
							)}


						</>
					)}
				<IonAlert
					isOpen={showRemoveItemAlert}
					onDidDismiss={() => {
						this.setState({ showLastItemAlert: false });
					}}
					header={__('Are you sure you want to remove the selected item?')}
					message={__('')}
					buttons={[
						{
							text: __('No'),
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => this.setState({ showRemoveItemAlert: false, removeItemAlertFunction: () => null }),
						},
						{
							text: __('Yes'),
							handler: () => this.setState({ showRemoveItemAlert: false }, () => removeItemAlertFunction())
						},
					]}
				/>
			</>
		);
	}
}

const mapStateToProps = (store) => {
	return {
		profile: store.profile.profile,
		isRemoveVoucherModalOpen: store.profile.isRemoveVoucherModalOpen,
		clientProfile: store.common.clientProfile,
		taxContentLoading: store.common.taxContentLoading,
		taxCalculated: store.common.taxCalculated,
		serviceChargeBoxAlertOpen: store.common.serviceChargeBoxAlertOpen,
	};
};

export default connect(mapStateToProps)(withRouter(withTranslation(OrderContent)));
