import React from 'react';
import MapContainer from '../../components/map';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { IonButton, IonIcon } from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import { withRouter } from 'react-router';
import './index.css';
import { isWebConfig } from '../../lib/utils';

const Location = ({ __, history }) => (
  <Layout headerTitle={__('Location')} scrollY={false} contentClassName="map-page" noPadding={true}>
		<MapContainer />
	</Layout>
);
export default withRouter(withTranslation(Location));
