import React from 'react';
import { connect } from 'react-redux';
import { IonButton, IonTextarea, IonIcon, IonItem, IonLabel, IonList, IonRadioGroup, IonRadio, IonAlert, IonCheckbox } from '@ionic/react';
import { withRouter } from 'react-router';
import { starOutline, star } from 'ionicons/icons';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { getRestaurants, logout, sendFeedback, setCommonModal } from '../../store/actions';
import { FieldError, Title, SmallText, Spacer, NormalText, StrongText } from '../../components/common';
import { validateForm, isDefined, isWebConfig, goBack, forwardTo } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import Mobiscroll from '../../components/mobiscroll';
import './index.css';
import { getConfig } from '../../appConfig';

const { SelectOption } = Mobiscroll;

class Feedback extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.resetState(),
		};
		this.formConfig = {
			food: { type: 'integer', required: !getConfig().flags.removeCustomerService, invalidValue: 0 },
			service: { type: 'integer', required: !getConfig().flags.removeCustomerService, invalidValue: 0 },
			selectedRestaurant: { type: 'select', required: true, invalidValue: null },
		};
	}

	resetState = () => ({
		food: 0,
		service: 0,
		customerService: !getConfig().flags.removeCustomerService,
		techSupport: !getConfig().flags.techSupport,
		commentService: '',
		commentTech: '',
		radioGroup: 'customerService',
		selectedRestaurant: null,
		formErrors: {},
		orderTypeFeedback: '',
	});

	createStarSet(size, name) {
		return <>{[...Array(size).keys()].map((i) => this.createStar(name, i + 1))}</>;
	}

	createStar = (name, value) => {
		if (this.state[name] >= value) {
			return <IonIcon key={value} icon={star} className="star" onClick={() => this.setValue(name, value)} />;
		} else {
			return <IonIcon key={value} icon={starOutline} className="star" color="medium" onClick={() => this.setValue(name, value)} />;
		}
	};

	handleFeedback = () => {
		let formErrors = validateForm(this.formConfig, this.state);

		this.setState({ formErrors });
		if (Object.keys(formErrors).length === 0) {
			const { orderTypeFeedback, food, service, commentService, commentTech, selectedRestaurant, techSupport, customerService } = this.state;
			const feedback = {
				food,
				service,
				commentService,
				commentTech,
				selectedRestaurant,
				techSupport,
				customerService,
				orderTypeFeedback,
			};

			if(techSupport){
				this.setState({
					modalHeader:'Support ticket created',
					modalMessage:'Our support team will be in touch with you via email within 48 hours'
				})
			}
			if(customerService){
				this.setState({
					modalHeader:'Thank you',
					modalMessage:'Feedback is processed'
				})
			}
			this.props.dispatch(sendFeedback(feedback));
			this.setState({ ...this.resetState() });
		}
	};
	selectRestaurant = (selectedRestaurantId) => {
		this.setState({ selectedRestaurant: selectedRestaurantId });
		if (selectedRestaurantId !== null) {
			let formErrors = { ...this.state.formErrors };
			formErrors.selectedRestaurant = null;
			this.setState({ formErrors });
		}
	};

	setValue(field, value) {
		if (['customerService', 'techSupport'].indexOf(field) !== -1) {
			const decheckedKey = field === 'customerService' ? 'techSupport' : 'customerService';
			this.setState({ [field]: value, [decheckedKey]: false });
		} else {
			this.setState({ [field]: value });
		}
	}

	formatDataForSelect = (stores) => {
		const { __ } = this.props;
		let arrForSelect = [];
		stores.forEach((store) => {
			if (isDefined(store.is_published)) {
				if (store.is_published) {
					arrForSelect.push({ text: store.name, value: store.id });
				}
			}
		});
		return [{ text: __('Select Shop'), value: null }, ...arrForSelect];
	};

	handleChange = (val) => {
		this.setValue(val, true);
		this.setState({
			radioGroup: val,
		});
	};
	backHandler = () => {
		if (this.props.location?.state?.fromBillPay) {
			forwardTo('/table-bill-pay');
		} else {
			goBack();
		}
	};
	componentDidMount() {
		const { restaurants } = this.props;
		if (!restaurants || restaurants.length === 0) {
			this.props.dispatch(getRestaurants());
		}
		const selectedRestaurant = this.props.location?.state?.selectedRestaurant;
		if (selectedRestaurant) {
			const selectedRestaurantId = restaurants.find((restaurant) => restaurant.pos_location_id == selectedRestaurant)?.id;
			this.selectRestaurant(selectedRestaurantId);
			this.setState({ customerService: false, techSupport: true, radioGroup: 'techSupport' });
			if (this.props.location?.state?.fromBillPay) {
				this.setState({ orderTypeFeedback: 'bill_pay' });
			}
		}
	}
	render() {
		const { __, isFeedbackModalOpen, restaurants } = this.props;
		const { techSupport, commentService, commentTech, formErrors } = this.state;
		const stores = restaurants.sort((a, b) => {
			if (isDefined(a.name) && isDefined(b.name)) {
				if (a.name < b.name) {
					return -1;
				}
				if (a.name > b.name) {
					return 1;
				}
				return 0;
			}
			return 0;
		});
		return (
			<Loading transparent>
				<Layout showHamburger backHandler={this.backHandler} color="transparent" title={__('Leave feedback')} >
					<div className="absolute-content scrollable-y">
						<Title>{techSupport ? __('Get support') : __('Leave feedback')}</Title>
						{getConfig().flags.removeCustomerService &&
							<>
								<NormalText className='block'>{__('Having an issue with our app? Use this form to contact our friendly support team for help.')}</NormalText>
								<Spacer size={1} />
							</>
						}
						{(!getConfig().flags.removeCustomerService && !getConfig().flags.removeTechSupport) &&
							<div className="box-holder no-padding box-wrapper">
								<IonList lines="none">
									<IonItem lines="none" onClick={() => this.handleChange('customerService')}>
										<div tabIndex="-1"></div>
										<IonLabel>
											<NormalText className="primary-color block">{__('Customer service')}</NormalText>
											<SmallText>{__('Any comments related to your visit')}</SmallText>
										</IonLabel>
										<IonCheckbox checked={this.state.radioGroup == 'customerService'} className='round-checkbox' value={'customerService'} slot="start" color="primary" />

									</IonItem>
									<IonItem onClick={() => this.handleChange('techSupport')}>
										<div tabIndex="-1"></div>
										<IonLabel>
											<NormalText className="primary-color block">{__('Tech support')}</NormalText>
											<SmallText>{__('Any technical comments related to our app')}</SmallText>
										</IonLabel>
										<IonCheckbox checked={this.state.radioGroup == 'techSupport'} className='round-checkbox' value={'techSupport'} slot="start" color="primary" />

									</IonItem>
								</IonList>
							</div>
						}

						{techSupport ? (
							<>
								<div className="">
									<div className="feedback-box-wrapper input-field-container">
										{!getConfig().flags.removeCustomerService &&
											<StrongText>{__('Comments')}:</StrongText>
										}
										<div className="input-field-wrapper">
											<IonTextarea className="thiner-text" value={commentTech} onIonChange={(event) => this.setValue('commentTech', event.detail.value)}></IonTextarea>
										</div>
									</div>
								</div>
								<div className="input-field-container">
									<StrongText>{__('Location')}</StrongText>
									<IonItem lines="none" className="input-field-wrapper dropdown-field">
										<SelectOption
											display="center"
											onSet={(e, a) => this.selectRestaurant(a.getVal())}
											data={this.formatDataForSelect(stores)}
											label={__('Location')}
											value={this.state.selectedRestaurant}
											inputStyle="box"
											placeholder={__('Select Restaurant')}
											setText={__('OK')}
											cancelText={__('Cancel')}
										/>
									</IonItem>
									{formErrors.selectedRestaurant ? <FieldError className="field-error pad10" value={__(formErrors.selectedRestaurant)} /> : null}

								</div>
							</>
						) : (
							<>
								<>

									<div className="feedback-box-wrapper input-field-container">
										<StrongText>{__('Comments')}:</StrongText>
										<div className="commentService input-field-wrapper">
											<IonTextarea className="thiner-text" value={commentService} onIonChange={(event) => this.setValue('commentService', event.detail.value)}></IonTextarea>
										</div>
									</div>

									<div className="input-field-container">
										<StrongText>{__('Location')}</StrongText>
										<IonItem lines="none" className="input-field-wrapper dropdown-field">
											<SelectOption
												display="center"
												onSet={(e, a) => this.selectRestaurant(a.getVal())}
												data={this.formatDataForSelect(stores)}
												label="Location"
												value={this.state.selectedRestaurant}
												inputStyle="box"
												placeholder={__('Select Restaurant')}
												setText={__('OK')}
												cancelText={__('Cancel')}
											/>
										</IonItem>
									</div>
									{formErrors.selectedRestaurant ? <FieldError className="field-error pad10" value={__(formErrors.selectedRestaurant)} /> : null}
								</>

								<div className="box-wrapper no-padding default-color">
									<div className="stars-row">
										<div className="flex-col-wrapper flex-align-center">
											<div>
												<NormalText>{__('Rate our food')}</NormalText>
											</div>
											<div className="righted">{this.createStarSet(5, 'food')}</div>
										</div>
										{this.state.formErrors.food ? <FieldError className="field-error pad5l" value={__(this.state.formErrors.food)} /> : null}
									</div>
									<div className="stars-row">
										<div className="flex-col-wrapper flex-align-center">
											<div>
												<NormalText>{__('Rate our service')}</NormalText>
											</div>
											<div className="righted">{this.createStarSet(5, 'service')}</div>
										</div>
										{this.state.formErrors.service ? <FieldError className="field-error pad5l" value={__(this.state.formErrors.service)} /> : null}
									</div>
								</div>
							</>
						)}

						<IonButton expand="block" color="primary" onClick={this.handleFeedback}>
							{__('Submit')}
						</IonButton>
						{this.props.profile.is_guest && (
							<IonButton expand="block" color="primary" fill="outline" onClick={() => this.props.dispatch(logout())}>
								{__('Go back')}
							</IonButton>
						)}
					</div>
				</Layout>
				<IonAlert
				className='feedback-alert-wrapper'
					isOpen={isFeedbackModalOpen}
					onDidDismiss={() => this.props.dispatch(setCommonModal('isFeedbackModalOpen', false))}
					header={__(this.state.modalHeader)}
					message={__(this.state.modalMessage)}
					buttons={[
						{
							text: __('OK'),
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => {
								this.props.dispatch(setCommonModal(('isFeedbackModalOpen', false)));
								this.setState({
									food: 0,
									service: 0,
									customerService: !getConfig().flags.removeCustomerService,
									techSupport: !getConfig().flags.techSupport,
									commentService: '',
									commentTech: '',
								});
							},
						},
					]}
				/>
			</Loading>
		);
	}
}
const stateToProps = (store) => {
	const { profile } = store.profile;
	const { appVersion, isFeedbackModalOpen } = store.common;
	const { restaurants } = store.restaurants;
	return {
		appVersion,
		isFeedbackModalOpen,
		restaurants: restaurants || [],
		profile,
	};
};

export default connect(stateToProps)(withRouter(withTranslation(Feedback)));
