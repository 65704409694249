import React from 'react';
import { IonItem, IonRow, IonCol, IonGrid, IonButton, IonIcon, IonInput } from '@ionic/react';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import { Spacer, NormalText, FieldError, StrongText } from '../../components/common';
import { connect } from 'react-redux';
import { forwardTo, isEmptyObject, validateForm } from '../../lib/utils';
import Basket from '../../lib/basket';
import { withRouter } from 'react-router';
import './index.css';
import successIcon from '../../assets/images/success-icon.svg';
import { logout, sendTablePaymentEmail } from '../../store/actions';
import { chevronDown, chevronUp, closeCircleSharp } from 'ionicons/icons';

class BillCompleted extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			orderId: null,
			restaurantId: null,
			isTableItemsOverviewVisible: false,
			guestEmail: '',
			formErrors: {},
			showMessage: false,
		};
		this.formConfig = {
			email: { type: 'email', required: true },
		};
	}
	sendPaymentConformationEmail = (status) => {
		let formErrors = validateForm(this.formConfig, { email: this.state.guestEmail });

		this.setState({ formErrors }, () => {
			if (Object.keys(formErrors).length === 0) {
				const tableDataLocalStorage = JSON.parse(localStorage.getItem('bill_data')) || {};
				const data = {
					email: this.state.guestEmail,
					id: JSON.parse(localStorage.getItem('table_payment_data')),
					amount: Basket.formatPrice(tableDataLocalStorage?.payment_amount / 100),
					status,
				};
				this.props.dispatch(sendTablePaymentEmail(data));
			}
		});
	};
	componentDidMount = () => {
		setTimeout(() => {
			this.setState({ showMessage: true });
		}, 5000);
		const tableDataLocalStorage = JSON.parse(localStorage.getItem('bill_data')) || {};

		const { automaticServiceChargePercentage, automaticServiceChargeValue } = this.calculateServiceCharge(tableDataLocalStorage.paid_items);
		this.setState({ automaticServiceChargePercentage, automaticServiceChargeValue });
	};

	formatCardName = (pay_method) => {
		const usedCard = this.props.cards.find((card) => card.id === pay_method);
		if (usedCard) {
			const { brand, last4 } = usedCard;
			return {
				brand,
				digits: `**** ${last4}`,
			};
		} else {
			return { brand: pay_method };
		}
	};
	calculateServiceCharge = (items) => {
		let total = 0;
		let totalPlusServiceCharge = 0;
		for (let i = 0; i < items.length; i++) {
			if (items[i].grossUnitAmount !== items[i].unitAmount) {
				total = total + parseFloat(items[i].grossUnitAmount);
				totalPlusServiceCharge = totalPlusServiceCharge + parseFloat(items[i].unitAmount);
			}
			for (let j = 0; j < items[i].subLineItems.length; j++) {
				if (items[i].subLineItems[j].grossUnitAmount !== items[i].subLineItems[j].unitAmount) {
					total = total + parseFloat(items[i].subLineItems[j].grossUnitAmount);
					totalPlusServiceCharge = totalPlusServiceCharge + parseFloat(items[i].subLineItems[j].unitAmount);
				}
			}
		}
		if (total > 0) {
			const automaticServiceChargeValue = totalPlusServiceCharge - total;
			const num = ((parseFloat(totalPlusServiceCharge) - parseFloat(total)) / parseFloat(total)) * 100;
			const automaticServiceChargePercentage = `${Math.floor(num * 10) / 10}%`;
			return { automaticServiceChargePercentage, automaticServiceChargeValue };
		} else {
			return {
				automaticServiceChargePercentage: 0,
				automaticServiceChargeValue: '0%',
			};
		}
	};
	render() {
		const { __, tableData } = this.props;
		const { isTableItemsOverviewVisible, guestEmail, formErrors } = this.state;
		const data = this.props.billStatus;
		const tableDataLocalStorage = JSON.parse(localStorage.getItem('bill_data')) || {};
		const dataFromTable = isEmptyObject(tableData) ? Basket.bill_data : tableData;
		const lastTransaction = dataFromTable.pay_events
			.filter((el) => el.type == 'pay_method')
			.filter((event) => event.user_id == this.props.profile.id)
			.pop();
		return (
			<Loading>
				<Layout color="transparent" headerTitle={__('Order Completed')}>
					<div className="absolute-content scrollable-y">
						<div className="centered bill-pay-status-wrapper">
							{lastTransaction && (
								<>
									{lastTransaction.status == 'paid' ? (
										<>
											<img className="block" alt="success icon" src={successIcon}></img>
											<NormalText className="block">{__('Payment Completed - Thank You')}</NormalText>
										</>
									) : (
										<>
											<IonIcon expand="block" color="danger" icon={closeCircleSharp} className="success-icon" />
											<NormalText className="block">{__('Payment Failed')}</NormalText>
										</>
									)}
								</>
							)}
						</div>

						<Spacer size={1} />
						{dataFromTable.bill_completed && (
							<div className="box-content centered">
								<NormalText className="primary-color">{__("Your Table's Bill is now fully paid and you may leave")}</NormalText>
							</div>
						)}
						<Spacer size={1} />

						<NormalText className="block">
							{__('Table')} {tableDataLocalStorage.table_name}
						</NormalText>
						{tableDataLocalStorage?.paid_items.length > 0 && (
							<IonGrid className={`${this.state.isTableItemsOverviewVisible ? 'items-overwiew-opened' : ''} box-wrapper space-between pointer table-items-wrapper`}>
								<IonRow
									style={{ width: '100%' }}
									onClick={() =>
										this.setState({
											isTableItemsOverviewVisible: !this.state.isTableItemsOverviewVisible,
										})
									}
								>
									<IonCol>
										<StrongText>{this.state.isTableItemsOverviewVisible ? __('Hide items') : __('View items')}</StrongText>
									</IonCol>
									<IonCol className="righted">
										<IonIcon icon={this.state.isTableItemsOverviewVisible ? chevronUp : chevronDown}></IonIcon>
									</IonCol>
								</IonRow>
								{isTableItemsOverviewVisible && (
									<>
										{tableDataLocalStorage.paid_items.map((el) => (
											<>
												<IonRow style={{ width: '100%' }}>
													<IonCol size="8">
														<NormalText>{el.itemName}</NormalText>
													</IonCol>
													<IonCol size="4" className="righted">
														<NormalText>{Basket.formatPrice(el.grossUnitAmount)}</NormalText>
													</IonCol>
												</IonRow>
												{el.subLineItems.map((subitem, i) => (
													<IonRow key={`sub-${i}`} style={{ width: '100%', paddingLeft: '20px', borderTop: 'none' }}>
														<IonCol size="8">
															<NormalText>{subitem.itemName}</NormalText>
														</IonCol>
														<IonCol size="4" className="righted">
															<NormalText>{Basket.formatPrice(subitem.grossUnitAmount)}</NormalText>
														</IonCol>
													</IonRow>
												))}
											</>
										))}
										{this.state.automaticServiceChargeValue > 0 && (
											<IonRow>
												<IonCol size="8">{`${__('Service Charge')} ${this.state.automaticServiceChargePercentage}`}</IonCol>
												<IonCol size="4" className="righted">
													{Basket.formatPrice(this.state.automaticServiceChargeValue)}
												</IonCol>
											</IonRow>
										)}
									</>
								)}
							</IonGrid>
						)}
						{!this.state.isTableItemsOverviewVisible ? <Spacer size={1} /> : null}
						<IonGrid className={`box-wrapper-list box-wrapper space-between pointer ${this.state.isTableItemsOverviewVisible ? 'items-overwiew-opened' : ''}`}>
							{tableDataLocalStorage.payment_amount ? (
								<IonRow>
									<IonCol>{__('Items Total')}</IonCol>
									<IonCol className="righted">{Basket.formatPrice(tableDataLocalStorage?.payment_amount / 100)}</IonCol>
								</IonRow>
							) : null}
							{tableDataLocalStorage.service_charge_applied ? (
								<IonRow>
									<IonCol>
										{__('Tip')} {tableDataLocalStorage?.service_charge_percentage ? `${tableDataLocalStorage?.service_charge_percentage}%` : `${__('Custom')}`}
									</IonCol>
									<IonCol className="righted">{Basket.formatPrice(tableDataLocalStorage?.service_charge_applied / 100)}</IonCol>
								</IonRow>
							) : null}
							<IonRow>
								<IonCol size="8">
									{__('Amount paid')}{' '}
									{tableDataLocalStorage.people_paying_for ? (
										<i style={{ opacity: 0.7 }}>
											{tableDataLocalStorage?.people_paying_for} {__('of')} {tableDataLocalStorage?.total_members_to_pay} {__('people')}
										</i>
									) : null}
								</IonCol>
								<IonCol size="4" className="righted">
									{Basket.formatPrice(data?.amount / 100)}
								</IonCol>
							</IonRow>
						</IonGrid>
						<Spacer size={1} />
						<NormalText>{__('Payment')}</NormalText>
						<IonGrid style={{ marginTop: '5px' }} className="box-wrapper space-between pointer ">
							<IonRow>
								<IonCol>
									<NormalText className="block">{this.formatCardName(tableDataLocalStorage?.payment_token).brand}</NormalText>
									{this.formatCardName(tableDataLocalStorage?.payment_token).digits && <NormalText>{this.formatCardName(tableDataLocalStorage?.payment_token).digits}</NormalText>}
								</IonCol>
								<IonCol className="righted">{Basket.formatPrice(data?.amount / 100)}</IonCol>
							</IonRow>
						</IonGrid>
						<Spacer />
						{this.props.profile.is_guest && (
							<>
								{lastTransaction?.status != 'paid' && (
									<>
										<NormalText className="block">{__('Send Payment Confirmation')}</NormalText>
										{!this.props.paymentEmailSent ? (
											<>
												<IonItem lines="none" className="input-field-wrapper">
													<IonInput
														value={guestEmail}
														onIonChange={(e) => this.setState({ guestEmail: e.target.value })}
														placeholder={__('Email Address for Payment Confirmation')}
													/>
												</IonItem>
												{formErrors.email ? <FieldError className="field-error" value={__(formErrors.email)} /> : null}
											</>
										) : (
											<>
												<div className="payment-confirmation-wrapper">
													<img alt="success icon" src={successIcon}></img>
													<NormalText>
														{__('Your Payment Confirmation has been sent to')} <b>{this.state.guestEmail}</b>
													</NormalText>
												</div>
											</>
										)}
									</>
								)}
								<Spacer size={1} />
								{!this.props.paymentEmailSent && (
									<IonButton onClick={() => this.sendPaymentConformationEmail(lastTransaction?.status)} fill="outline" color="primary" expand="block">
										{__('Send email')}
									</IonButton>
								)}
							</>
						)}
						<IonButton
							fill="outline"
							color="primary"
							expand="block"
							onClick={() => {
								if (this.props.profile.is_guest) {
									this.props.dispatch(logout(null, '/table-bill-pay'));
								} else {
									Basket.reset();
									localStorage.removeItem('bill_data');
									forwardTo('/table-bill-pay');
								}
							}}
						>
							{__('Go Back')}
						</IonButton>
						<IonButton
							fill="clear"
							color="secondary"
							className="underlined link"
							expand="block"
							onClick={() => {
								Basket.reset();
								forwardTo('/feedback', {
									fromBillPay: true,
									selectedRestaurant: tableDataLocalStorage?.pos_location_id,
								});
							}}
						>
							{__('Leave feedback')}
						</IonButton>
					</div>
				</Layout>
			</Loading>
		);
	}
}

const mapStateToProps = (store) => {
	const orders = store.orders;
	const { restaurants, tableData } = store.restaurants;
	const { paymentEmailSent } = store.common;
	let lastOrder = null;
	const orderHistory = orders.orderHistory;
	if (orderHistory && orderHistory.length > 0) {
		lastOrder = orderHistory[0];
	}
	const { profile } = store.profile;
	return {
		lastOrder,
		cards: orders.cards || [],
		billStatus: orders.billStatus || {},
		restaurants: restaurants || [],
		tableData: tableData,
		profile,
		paymentEmailSent,
	};
};

export default connect(mapStateToProps)(withRouter(withTranslation(BillCompleted)));
