import React from 'react';
import { IonButton, IonInput, IonToggle, IonItem, IonLabel, IonList, IonAlert, IonCheckbox } from '@ionic/react';
import { connect } from 'react-redux';
import Layout from '../../components/layout';
import PasswordInput from '../../components/passwordInput';
import { forwardTo, getDefaultRoute, isDefined } from '../../lib/utils';
import { registerRequest, setModal, setSysLocale, setRegisterForm } from '../../store/actions';
import { withTranslation } from '../../lib/translate';
import {
  FieldError,
  Title,
  Spacer,
  NormalText,
  StrongText,
  Subtitle,
} from '../../components/common';
import { validateForm } from '../../lib/utils';
import Loading from '../../components/spinner';
import './index.css';
import Basket from '../../lib/basket';
import { getConfig } from '../../appConfig';
import { Device } from '@capacitor/device';
import SwipeableTabs from '../../components/swipeableTabs';

class CreateAccounf extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			formErrors: {},
			loginFormErrors: {},
			password: '',
			is_subscribed: false,
			email: this.props?.guestUserData?.guest_email ? this.props.guestUserData.guest_email : '',
			login_password: '',
			login_email: this.props?.guestUserData?.guest_email ? this.props.guestUserData.guest_email : '',
			accepted_terms_and_conditions: false,
		};
		this.handleInput = this.handleInput.bind(this);
		this.handleRegister = this.handleRegister.bind(this);
		this.handleGuestReigister = this.handleGuestReigister.bind(this);
		this.navToTerms = this.navToTerms.bind(this);
		this.formConfig = {
			first_name: { type: 'text', required: false },
			mobile: { type: 'text', required: false },
			email: { type: 'email', required: true },
			password: { type: 'password', required: true },
			accepted_terms_and_conditions: { type: 'toggle', toggle: true },
		};
		this.loginFormConfig = {
			email: { type: 'text', required: true },
			password: { type: 'password', required: true },
		};
	}

	handleInput(key, val) {
		this.setState({ [key]: val });
	}
	handleLogin = () => {
		const { login_email, login_password } = this.state;
		let loginFormErrors = validateForm(this.formConfig, { email: login_email, password: login_password });
		this.setState({ loginFormErrors });
		if (Object.keys(loginFormErrors).length === 0) {
			this.props.dispatch(loginRequest({ username: login_email, password: login_password, referrer: '/checkout' }));
		}
	};
	handleRegister() {
		const { registerFormData, guestUserData } = this.props;
		const { guest_mobile_code, guest_mobile_value, guest_email, guest_first_name, guest_mobile } = guestUserData;
		registerFormData.email = this.state.email;
		registerFormData.first_name = guest_first_name;
		registerFormData.mobile = guest_mobile;
		registerFormData.mobile_code = guest_mobile_code;
		registerFormData.mobile_value = guest_mobile_value;
		registerFormData.accepted_terms_and_conditions = this.state.accepted_terms_and_conditions;
		registerFormData.password = this.state.password;
		registerFormData.is_subscribed = this.state.is_subscribed;
		let formErrors = validateForm(this.formConfig, registerFormData);
		this.setState({ formErrors });
		if (Object.keys(formErrors).length === 0) {
			this.props.dispatch(registerRequest({ referrer: this?.props?.location?.state?.referrer }));
		}
	}
	handleGuestReigister() {
		const { registerFormData, guestUserData } = this.props;
		const timestamp = new Date().getTime();
		const { guest_mobile_code, guest_mobile_value, guest_first_name, guest_mobile, guest_accepted_terms_and_conditions } = guestUserData;
		guestUserData.guest_email = this.state.email;
		let guest_email = guestUserData.guest_email == '' || !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(guestUserData.guest_email) ? 'guest@5loyalty.com' : guestUserData.guest_email;
		registerFormData.email = `${timestamp}_${guest_email}`;
		registerFormData.first_name = guest_first_name;
		registerFormData.mobile = guest_mobile;
		registerFormData.accepted_terms_and_conditions = guest_accepted_terms_and_conditions;
		registerFormData.guest = true;
		registerFormData.password = timestamp;
		registerFormData.mobile_code = guest_mobile_code;
		registerFormData.mobile_value = guest_mobile_value;

		this.props.dispatch(registerRequest({ referrer: '/checkout' }));
	}
	componentDidMount() {
		const { loggedIn } = this.props.auth;
		Device.getLanguageCode().then((res) => {
			const sysLocale = res.value.substr(0, 2);
			if (sysLocale) {
				this.props.dispatch(setSysLocale(sysLocale));
			}
		});

		if (!getConfig().flags.hasRegisterOnGuestCheckout) {
			this.handleGuestReigister();
		}

		if (loggedIn) {
			const defaultRoute = getDefaultRoute(this.props.navConfig);
			forwardTo(defaultRoute.path);
		}
	}

	returnToLogin = (history) => history.goBack();

	navToTerms = () => forwardTo('/terms');
	calculateOrderPoints = () => {
		let calculatedPoints = Math.floor(Basket.getTotal() * getConfig().api_config.points_value_percentage);
		return calculatedPoints.toFixed(0);
	};
	render() {
		const { __, isRegisterModalOpen } = this.props;
		const { password, is_subscribed, login_password, accepted_terms_and_conditions } = this.state;
		const hasEarnLoyalty = isDefined(getConfig().api_config) && isDefined(getConfig().api_config.points_value_percentage) ? getConfig().api_config.points_value_percentage : 0;
		return (
			<Loading>
				<Layout headerWithTitle={true} title={__('Create Account')} color="transparent">
					<SwipeableTabs
						history={this.props.location}
						tabs={[
							{
								label: __('Register'),
								tabContent: (
									<div className="absolute-content scrollable-y">
										{hasEarnLoyalty ? (
											<div>
												<NormalText>{__('It’s not too late')}...</NormalText>
												<Spacer />
												<NormalText>{__('You can still earn')}</NormalText> <StrongText>{this.calculateOrderPoints()}</StrongText>{' '}
												<NormalText>{__('loyalty points for this order, simply complete the form below to sign up')}</NormalText>
											</div>
										) : (
											<div>
												<NormalText>{__(getConfig().general.guestMessageOnCheckout)}</NormalText>
											</div>
										)}
										
										<IonList>
											<div className={`input-field-container`}>
												<StrongText>{__('Email')}</StrongText>
												<IonItem lines="none" className="input-field-wrapper">
													<IonInput
														onIonChange={(e) => this.handleInput('email', e.target.value.trim())}
														onIonBlur={(e) => {
															const autoInput = document.querySelector('input[type="email"]:-webkit-autofill');
															if (autoInput) {
																this.handleInput('email', autoInput.value);
															}
															this.handleInput('email', e.target.value);
														}}
														clearInput
														required={Basket.getOrderType() !== 'Table'}
														type="email"
														pattern="email"
														inputmode="email"
														value={this.state.email}
													></IonInput>
												</IonItem>
											</div>
											<FieldError className="field-error" value={__(this.state.formErrors.email)} />

											<div className={`input-field-container`}>
												<StrongText>{__('Password')}</StrongText>
												<IonItem lines="none" className="input-field-wrapper">
													{' '}
													<PasswordInput onIonChange={(e) => this.handleInput('password', e.target.value)} value={password} />
												</IonItem>
											</div>
											<FieldError className="field-error" value={__(this.state.formErrors.password)} />
											
											<div lines="none">
												<div tabIndex="-1"></div>
												<div className="toggle">
													<IonLabel>
														<StrongText>{__('Terms & conditions')}</StrongText>
														
														<div className="register-terms-wrapper">
															<IonCheckbox
																color="secondary"
																checked={accepted_terms_and_conditions}
																onIonChange={(e) => this.handleInput('accepted_terms_and_conditions', e.detail.checked)}
																selenium="terms"
															/>
															<IonLabel className="ion-text-wrap">
																<NormalText color="primary">
																	{__('By signing up you agree to') + ' '}{' '}
																	<span
																		className="pointer underlined primary-color"
																		selenium="termsLink"
																		onClick={() => forwardTo('/terms', { fromRegisterPage: true })}
																	>
																		{__('our terms and conditions')}
																	</span>{' '}
																	{__('and')}{' '}
																	<span
																		className="primary-color  pointer underlined"
																		selenium="privacyLink"
																		onClick={() => forwardTo('/privacy', { fromRegisterPage: true })}
																	>
																		{__('privacy policy')}
																	</span>
																</NormalText>
															</IonLabel>
														</div>
													</IonLabel>
													<FieldError className="field-error" value={__(this.state.formErrors.accepted_terms_and_conditions)} />
												</div>
											</div>
											<div lines="none">
												<div tabIndex="-1"></div>
												<div className="toggle">
													<IonLabel>
														<div className="register-terms-wrapper">
															<IonCheckbox
																color="secondary"
																checked={is_subscribed}
																selenium="subscribe"
																onIonChange={(e) => this.handleInput('is_subscribed', e.detail.checked)}
															/>
															<IonLabel className="ion-text-wrap">
																<NormalText color="primary">{__("I'd like to receive email updates that contain news, offers and promotions")}</NormalText>
															</IonLabel>
														</div>
													</IonLabel>
												</div>
											</div>
										</IonList>
										<div className="top-medium">
											<IonButton expand="block" color="primary" className="register-button" onClick={() => this.handleRegister()}>
												{__('Register')}
											</IonButton>
											<IonButton fill="clear" className="link underlined" expand="block" color="secondary" onClick={() => this.handleGuestReigister()}>
												{__('No thanks')}
											</IonButton>
										</div>
									</div>
								),
							},
							{
								label: __('Login'),
								tabContent: (
									<div className="absolute-content scrollable-y">
										<IonList>
											<div className={`input-field-container`}>
												<StrongText>{__('Email')}</StrongText>
												<IonItem lines="none" className="input-field-wrapper">
													<IonInput
														onIonChange={(e) => this.handleInput('login_email', e.target.value.trim())}
														onIonBlur={(e) => {
															const autoInput = document.querySelector('input[type="email"]:-webkit-autofill');
															if (autoInput) {
																this.handleInput('login_email', autoInput.value);
															}
															this.handleInput('login_email', e.target.value);
														}}
														clearInput
														required={Basket.getOrderType() !== 'Table'}
														type="email"
														pattern="email"
														inputmode="email"
														value={this.state.login_email}
													></IonInput>
												</IonItem>
											</div>
											<FieldError className="field-error" value={__(this.state.loginFormErrors.email)} />

											<div className={`input-field-container`}>
												<StrongText>{__('Password')}</StrongText>
												<IonItem lines="none" className="input-field-wrapper">
													{' '}
													<PasswordInput onIonChange={(e) => this.handleInput('login_password', e.target.value)} value={login_password} />
												</IonItem>
											</div>
											<FieldError className="field-error" value={__(this.state.loginFormErrors.password)} />
										</IonList>
										<div className="top-medium">
											<IonButton expand="block" color="primary" className="register-button" onClick={() => this.handleLogin()}>
												{__('Login')}
											</IonButton>
										</div>
									</div>
								),
							},
						]}
					/>
				</Layout>
				<IonAlert
					isOpen={isRegisterModalOpen}
					onDidDismiss={() => this.props.dispatch(setModal('isRegisterModalOpen', false))}
					header={__('Success')}
					message={__('Register processed. Please check your mail')}
					buttons={[
						{
							text: __('Close'),
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => this.props.dispatch(setModal(('isRegisterModalOpen', false))),
						},
					]}
				/>
			</Loading>
		);
	}
}

const stateToProps = (state) => {
	const { auth, isRegisterModalOpen, registerFormData, guestUserData } = state.profile;
	const { navConfig } = state.common;
	return {
		auth,
		isRegisterModalOpen,
		registerFormData,
		navConfig,
		guestUserData,
	};
};

export default connect(stateToProps)(withTranslation(CreateAccounf));
