/* eslint-disable no-mixed-spaces-and-tabs */
import { IonButton, IonIcon, IonInput, IonItem } from '@ionic/react';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import { FieldError, NormalText, SmallText, Spacer, StrongText, Subtitle } from '../../components/common';
import Modal from '../../components/modal';
import Basket from '../../lib/basket';
import { withTranslation } from '../../lib/translate';
import { deepIsDefined, goBack, validateForm } from '../../lib/utils';
import { checkDiscount, checkMultiBasketDiscount, sendVoucherCode, showToast } from '../../store/actions';
import './index.css';
import { chevronForwardOutline } from 'ionicons/icons';

class ApplyVouchers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			discounts: null,
			selectedVoucher: null,
			showModal: null,
			voucherCode: '',
			disabledAppliedButton: false,
			formErrors: {
				voucherCode: '',
			},
		};
		this.formConfig = {
			voucherCode: { type: 'text', required: true },
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;
		if (Basket.has_multi_basket) {
			dispatch(checkMultiBasketDiscount(Basket.parseBasketForMultiBasketCheckVouchers()));
		} else {
			dispatch(checkDiscount(Basket.parseBasketForCheckVouchers()));
		}
	}

	setVoucher = (voucher, add = false) => {
		this.setState({ selectedVoucher: voucher }, () => {
			const { vouchers, applicableVouchers } = this.props;
			const { selectedVoucher } = this.state;
			const appliedVoucher = vouchers.find((voucher) => voucher.id === selectedVoucher.id);
			const applicableVoucher = applicableVouchers.find((appliableVoucher) => appliableVoucher.id === selectedVoucher.id);
			if (Basket.canVoucherBeApplied(appliedVoucher, applicableVoucher)) {
				this.setState({ disabledAppliedButton: true }, () => {
					Basket.applyPoints(0);
					if (add) {
						this.addVoucher()

					}
				});
			} else {
				this.setState({ disabledAppliedButton: false });
			}
		});
	};

	addVoucher = () => {
		const { selectedVoucher } = this.state;
		const { vouchers, applicableVouchers, dispatch, __ } = this.props;
		const appliedVoucher = vouchers.find((voucher) => voucher.id === selectedVoucher.id);
		const applicableVoucher = applicableVouchers.find((appliableVoucher) => appliableVoucher.id === selectedVoucher.id);
		if (Basket.canVoucherBeApplied(appliedVoucher, applicableVoucher)) {
			this.setState({ selectedVoucher: null }, () => {
				Basket.addVoucher(appliedVoucher, applicableVoucher);
				goBack();
			});
		} else {
			dispatch(showToast(__('Your basket is higher then voucher cost. Please add more items in basket'), 'warning'));
		}
	};

	showModal = (show) => {
		this.setState({ showModal: show });
		this.clearFields();
	};

	clearFields = () => {
		this.setState({ voucherCode: '' });
	};

	sendCode = () => {
		const { dispatch, __ } = this.props;
		let formErrors = validateForm(this.formConfig, this.state, __);
		this.setState({ formErrors });
		if (Object.keys(formErrors).length === 0) {
			const { voucherCode } = this.state;
			const data = { code: voucherCode.toUpperCase() };
			this.clearFields();
			dispatch(sendVoucherCode(data, true));
			this.showModal(false);
		}
	};

	filterVouchers = (vouchers, appliedVouchers) => {
		const { applicableVouchers, availableBalance } = this.props;
		let filteredVouchers = [];
		if (applicableVouchers && applicableVouchers.length > 0) {
			applicableVouchers.forEach((discount) => {
				let findedVoucher = vouchers.find((voucher) => voucher.id === discount.id);
				if (getConfig().vouchersType === 2) {
					if (findedVoucher && availableBalance > findedVoucher.cost) {
						filteredVouchers.push(findedVoucher);
					}
				} else {
					if (findedVoucher) {
						filteredVouchers.push(findedVoucher);
					}
				}
			});
		}
		if (appliedVouchers && appliedVouchers.length > 0) {
			const ids = appliedVouchers.map((i) => i.id);
			return filteredVouchers.filter((filteredVoucher) => ids.indexOf(filteredVoucher.id) === -1);
		}
		return filteredVouchers;
	};
	filterOtherVouchers(arr1, arr2) {
		const idsToRemove = new Set(arr2.map(item => item.id));
		return arr1.filter(item => !idsToRemove.has(item.id));
	}
	render() {
		const { __, vouchers } = this.props;
		const { selectedVoucher, voucherCode, showModal, disabledAppliedButton } = this.state;
		const appliedVouchers = Basket ? Basket.applied_vouchers : [];
		const filteredVouchers = this.filterVouchers(vouchers, appliedVouchers);
		const otherVouchers = this.filterOtherVouchers(vouchers, filteredVouchers)

		return (
			<>
				<div className="flex-row-wrapper ">
					{getConfig().flags.showAllVouchersOnCheckout ?
						<>
							<StrongText className='block text-to-update'>{__('Redeemable vouchers')}</StrongText>
							{filteredVouchers.length === 0 ? (
								<>
								<Spacer size={1}/>
								<NormalText className='block'>{__("You don't have any applicable vouchers in your wallet")}</NormalText>
								</>
							) : (

								<div className='box-wrapper'>
									{filteredVouchers.map(voucher => (
										<IonItem button key={voucher.reward.name} lines='none' onClick={() => { this.setVoucher(voucher, true); }}>
											<div>
												<StrongText className='block'>{voucher.reward.name}</StrongText>
												<SmallText>{`${__('Expiry date:')} ${moment(voucher.reward.expiry_date, 'YYYY/MM/DD').format('DD/MM/YYYY')}`}</SmallText>
											</div>
											<IonIcon slot='end' icon={chevronForwardOutline} />
										</IonItem>
									))}
								</div>
							)}

							<Spacer />
							{otherVouchers.length > 0 &&
								<>
									<StrongText className='text-to-update'>{__('Other vouchers')}</StrongText>
									<div className='box-wrapper'>
										{otherVouchers.map(voucher => (
											<IonItem button key={voucher.reward.name} lines='none'>
												<div>
													<StrongText className='block'>{voucher.reward.name}</StrongText>
													<SmallText>{`${__('Expiry date:')} ${moment(voucher.reward.expiry_date, 'YYYY/MM/DD').format('DD/MM/YYYY')}`}</SmallText>
												</div>
												<IonIcon slot='end' icon={chevronForwardOutline} />
											</IonItem>
										))}
									</div>
								</>
							}

						</>
						:
						<>
							<div className="redeem-voucher-field-wrapper">
								<div className="input-field-container">
									<StrongText className="primary-color">{__('Enter voucher code')}</StrongText>
									<IonItem className="input-field-wrapper" lines="none">
										<IonInput value={voucherCode} onIonChange={(e) => this.setState({ voucherCode: e.target.value })} placeholder={__('Voucher code')} />
									</IonItem>
								</div>
								<IonButton onClick={() => this.sendCode()} color="primary">
									{__('Submit')}
								</IonButton>
							</div>

							<Subtitle className="primary-color">{__('Apply vouchers')}</Subtitle>
							<div className=" vaucher-list" style={{ marginTop: '10px' }}>
								{filteredVouchers.length === 0 ? (
									<NormalText>{__("You don't have any applicable vouchers in your wallet")}</NormalText>
								) : (
									<NormalText>{__('The following vouchers from your wallet are applicable to this order.')}</NormalText>
								)}
								{filteredVouchers.length > 0 &&
									filteredVouchers.map((filteredVoucher, index) => {
										return (
											<div
												className={selectedVoucher && selectedVoucher.id === filteredVoucher.id ? 'selected-voucher-box' : ''}
												onClick={() => {
													this.setVoucher(filteredVoucher);
												}}
											>
												<img className={selectedVoucher && selectedVoucher.id === filteredVoucher.id ? 'voucher apply-voucher' : 'voucher apply-voucher-box'} src={filteredVoucher.reward.image} />

												<div className="voucher-description">
													<SmallText>{__(filteredVoucher.reward.name)}</SmallText>
													<SmallText>{`${__('Expires')} ${moment(filteredVoucher.reward.expiry_date, 'YYYY/MM/DD').format('DD/MM/YY')}`}</SmallText>
												</div>
												{filteredVoucher.id == this.state.selectedVoucher?.id && (
													<IonButton
														onClick={() => {
															this.addVoucher();
														}}
														className="apply-voucher-to-order-btn"
													>
														{__('Apply Voucher to Order')}
													</IonButton>
												)}
											</div>
										);
									})}
							</div>

						</>
					}
				</div>

				<Modal className="apply-voucher-modal" isOpen={!!showModal} onDidDismiss={() => this.setState({ showModal: null })} title={__('Enter code')}>
					<div className="flex-row-wrapper">
						<div className="centered flex-row-wrapper">
							<div className=" centered scrollable-y">
								<NormalText>{__('Enter a valid voucher code below to receive your reward')}</NormalText>
								<div className="lefted padded voucher-item">
									<IonItem style={{ '--min-height': '32px' }}>
										<IonInput value={voucherCode} onIonChange={(e) => this.setState({ voucherCode: e.target.value })} placeholder={__('Voucher code')} />
									</IonItem>
									<FieldError className="field-error" value={__(this.state.formErrors.voucherCode)} />
								</div>
							</div>
						</div>
						<div className="flex-min">
							<IonButton onClick={() => this.sendCode()} expand="full" color="primary">
								{__('Submit')}
							</IonButton>
						</div>
					</div>
				</Modal>
			</>
		);
	}
}

const mapStateToProps = (store) => {
	return {
		availableBalance: deepIsDefined(store, 'profile.profile.available_balance') ? store.profile.profile.available_balance : 0,
		basketUpadated: store.orders.basketUpdated,
		vouchers: store.profile.vouchers || null,
		applicableVouchers: store.profile.applicableVouchers || null,
	};
};

export default connect(mapStateToProps)(withTranslation(ApplyVouchers));
