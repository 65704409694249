import { getEnv } from './lib/env.js';

let appConfig = {
  theme: {
    nav: {
      label: 'WatchHouse',
    },
    showHeaderOnAuthRoutes: true,
    showInputIconsOnLogin: false,
    routesWithoutStaticHeader: ['/item-details'],
  },
  services: {
    google_maps: {
      google_api_key: getEnv('GOOGLE_API_KEY'),
    },
    // stripe_key: 'pk_test_SfLlfP41LhHVIG2vrA95ZrFt' //Kole
  },
  api: {
    // baseURL: "https://curry-leaf-api.5loyalty.com",
    baseURL: getEnv('BASE_URL'),
    // baseURL: 'https://hybrid-master.herokuapp.com',
    // baseURL: 'http://192.168.0.26:8000', //preda
    wordPressBaseURL: 'https://hybridapp.co.uk',
    nativeWallet: getEnv('NATIVE_WALLET_API'),
  },

  general: {
    routesWithoutBackButton: ['/dashboard', '/locations', '/social', '/login','/feedback'],
    dashboardText:
      'Take a taste adventure with our loyalty app and unlock a world of exclusive privileges with the club',
    clientName: 'WatchHouse',
    appExitRoutes: ['/', '/home', '/dashboard'],
    authRoutes: ['/login', '/register', '/reset-password'],
    routesWithDarkBg: [],
    routesWithBackButton: [
      '/cards',
      '/card-add',
      '/allergen-information',
      '/order-summary',
      '/order-completed',
      '/apply-vouchers',
      '/terms',
      '/privacy',
      '/contact-details',
      '/click-and-collect',
      '/order-to-table',
      '/history-details',
      '/delivery',
      '/my-membership',
      '/membership-terms',
      '/allergens',
      '/apply-loyalty',
      '/order',
      '/create-account',
      '/checkout',
    ],

    isReduxDevToolsOn: true,
    isWebPlatform: false,
  },
  appType: {
    hasOrdering: true,
    hasLoyalty: true,
    hasEmailValidationEnabled: true,
    hasCampaignManager: true,
    referAFriend: true,
    hasCancelOrder: false,
    isReward: false,
    oldLayoutConfirmationScreens: false,
    hasCollectedPay: false,
  },
  configS3: {
    imageNamePrefix: 'hyfive_profile_image_',
    bucketName: 'hybrid-apps-profile-images',
    region: 'eu-west-1',
  },

  firebaseConfig: {
    apiKey: getEnv('FIREBASE_API_KEY'),
    authDomain: getEnv('FIREBASE_AUTH_DOMAIN'),
    databaseURL: getEnv('FIREBASE_DATABASE_URL'),
    projectId: getEnv('FIREBASE_PROJECT_ID'),
    storageBucket: getEnv('FIREBASE_STORAGE_BUCKET'),
    messagingSenderId: getEnv('FIREBASE_MESSAGING_SENDER_ID'),
    appId: getEnv('FIREBASE_APP_ID'),
  },
  payment: 'stripe',
  localization: {
    defaultLocale: 'en',
  },
  socialLogin: {
    googleClientId: getEnv('GOOGLE_CLIENT_ID') || '',
    appleClientId: getEnv('APPLE_CLIENT_ID') || '',
  },
};

export const updateConfig = (newConfig) => (appConfig = newConfig);

export const getConfig = () => appConfig;

export default appConfig;
