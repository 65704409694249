import {
  IonAlert,
  IonButton,
  IonCheckbox,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import { FieldError, NormalText, Spacer, StrongText, Title } from '../../components/common';
import Layout from '../../components/layout';
import Mobiscroll from '../../components/mobiscroll';
import PasswordInput from '../../components/passwordInput';
import Loading from '../../components/spinner';
import moment from '../../lib/moment';
import { withTranslation } from '../../lib/translate';
import { forwardTo, getDefaultRoute, isWebConfig, validateForm } from '../../lib/utils';
import {
  registerRequest,
  setFullRegisterForm,
  setModal,
  setRegisterForm,
  setSysLocale,
  updateProfile,
} from '../../store/actions';
import { CLEAR_REGISTER_FORM } from '../../store/constants';
import SocialLogin from '../../components/SocialLogin';
import './index.css';
import { Device } from '@capacitor/device';
import BlockSeparator from '../../components/blockSeparator';
import MobileInput from '../../components/mobileInput';

const { DatePicker, SelectOption } = Mobiscroll;

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
      fromGiftVoucher: this.props?.location?.state?.fromGiftVoucher,
      dietaryRequirements: [],
      mobile_code: '',
      mobile_value: '',
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.navToTerms = this.navToTerms.bind(this);
    this.formConfig = {
      first_name: { type: 'text', required: getConfig().flags.hasFullNameOnRegister ? getConfig().flags.hasFullNameOnRegister : false },
      last_name: { type: 'text', required: getConfig().flags.hasFullNameOnRegister ? getConfig().flags.hasFullNameOnRegister : false },
      mobile: { type: 'text', required: false },
      email: { type: 'email', required: true },
      password: { type: 'password', required: true },
      accepted_terms_and_conditions: { type: 'toggle', toggle: true },
      birthday: { type: 'birthday', required: getConfig().restrictions?.min_registration_age ? true : false },
      referral_code: { type: 'text', required: false },
      local_house: { type: 'text', required: false },
      postal_code: { type: 'text', required: false },

    };
  }

  handleInput(key, val) {
    const { dispatch } = this.props;
    dispatch(setRegisterForm(key, val));
    if (key === 'accepted_terms_and_conditions') {
      let formErrors = { ...this.state.formErrors };
      formErrors.accepted_terms_and_conditions = undefined;
      this.setState({ formErrors });
    }
  }

  handleRegister() {
    const { registerFormData } = this.props;
    let formErrors = validateForm(this.formConfig, registerFormData);
    if (registerFormData.repeatPassword !== registerFormData.password) {
      formErrors.repeatPassword = "Passwords are not same";
    }
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      this.props.dispatch(registerRequest({ referrer: '/register' }));
    }
  }

  componentDidMount() {
    const { loggedIn } = this.props.auth;
    if (!this.props.location?.state?.saveRegisterData) {
      this.props.dispatch({
        type: CLEAR_REGISTER_FORM,
      });
    }

    Device.getLanguageCode().then((res) => {
      const sysLocale = res.value.substr(0, 2);
      if (sysLocale) {
        this.props.dispatch(setSysLocale(sysLocale));
      }
    });
    if (loggedIn) {
      const defaultRoute = getDefaultRoute(this.props.navConfig);
      forwardTo(defaultRoute.path, {
        fromLogin: this.props?.location?.state?.fromGiftVoucher ? true : false,
      });
    }
  }

  returnToLogin = (history) => history.goBack();

  navToTerms = () => forwardTo('/terms');



  validateSocialForm() {
    const { registerFormData } = this.props;
    const formErrors = validateForm(
      {
        ...this.formConfig,
        password: { type: 'password', required: false },
        email: { type: 'email', required: false },
      },
      registerFormData,
    );
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      return true;
    }
    return false;
  }
  formatDataForSelect = (langs) => {
    const { __ } = this.props;
    let arrForSelect = [];
    Object.keys(langs).forEach((key) => {
      arrForSelect.push({ text: __(langs[key]), value: key });
    });
    return [{ text: '', value: null }, ...arrForSelect];
  };
  formatDataForCheckBox = (data) => {
    const { __ } = this.props;
    let arrForSelect = [];
    Object.keys(data).forEach((key) => {
      arrForSelect.push({ text: __(data[key]), value: key });
    });
    return arrForSelect;
  };
  setMobileCode = (val) => {
    const { dispatch } = this.props;
    this.setState({ mobile_code: val });
    dispatch(setRegisterForm('mobile_code', val));
    dispatch(setRegisterForm('mobile_value', this.state.mobile_value || ''));

    dispatch(setRegisterForm('mobile', `${val}${this.state.mobile_value || ''}`));
  };
  setMobileValue = (val) => {
    const { dispatch } = this.props;
    this.setState({ mobile_value: val });
    this.handleInput('mobile_value', val);
    dispatch(setRegisterForm('mobile_value', val));
    dispatch(setRegisterForm('mobile', `${this.state.mobile_code}${val}`));
  };

  render() {
    const { __, isRegisterModalOpen, registerFormData, dispatch } = this.props;
    const { dietaryRequirements } = this.state;
    const defaultDateValue = moment().subtract(getConfig().restrictions?.min_registration_age || 18, 'years');
    const email = registerFormData.email;
    const password = registerFormData.password;
    const repeatPassword = registerFormData.repeatPassword || "";
    const first_name = registerFormData.first_name;
    const last_name = registerFormData.last_name;
    const mobile = registerFormData.mobile;
    const accepted_terms_and_conditions = registerFormData.accepted_terms_and_conditions;
    const is_subscribed = registerFormData.is_subscribed;
    const birthday = registerFormData.birthday;
    const mobile_code = registerFormData.mobile_code;
    const mobile_value = registerFormData.mobile_value;
    const gender = registerFormData.gender;
    const postal_code = registerFormData.postal_code
    const local_house = registerFormData.local_house
    const { loggedIn } = this.props.auth;
    const hasDOB = true;
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    const locale = getConfig().localization.defaultLocale
    const languages = Object.keys(getConfig().localization.supportedLocales);
    const hasGenderSelector = getConfig().flags.hasGenderSelector;
    const genderList = this.formatDataForSelect(getConfig().genders || { male: 'Male', female: 'Female' });
    const hasDietaryRequirements = getConfig().flags.hasDietaryRequirements;
    const dietaryRequirementsList = this.formatDataForCheckBox(getConfig().dietaryRequirements || { vegan: 'Vegan', glutenFree: 'Gluten free', none: 'None' });
    let hasSocialLogin = getConfig()?.flags && getConfig().flags.hasSocialLogin;
    const { hideSocialLoginNative } = getConfig()?.socialLogin || {};
    if (!isWebConfig() && hideSocialLoginNative) {
      hasSocialLogin = false
    }
    return (
      <Loading>
        <Layout headerTitle="Register" color="transparent">
          {!loggedIn ? (
            <div className="absolute-content scrollable-y">
              <Title>{this.state.registerWithEmail ? __('Sign up with email') : __('Sign up')}</Title>
              <NormalText className="block">
                {__(
                  'Create an account to earn & redeem loyalty, access exclusive vouchers and offers and other app-only perks.',
                )}
              </NormalText>
              <Spacer size={1} />
              {(this.state.registerWithEmail || !hasSocialLogin) ? (
                <>
                  <IonList>
                    {getConfig().flags.hasFullNameOnRegister && (
                      <div className="input-field-container input-field-container-multiple">
                        <StrongText className="primary-color">{__('Your name')}</StrongText>
                        <div>
                          <IonItem lines="none" className="input-field-wrapper">
                            <IonInput
                              placeholder={__('First name')}
                              onIonChange={(e) => this.handleInput('first_name', e.target.value)}
                              type="text"
                              pattern="text"
                              inputmode="text"
                              value={first_name}
                              selenium="firstName"
                            ></IonInput>
                          </IonItem>
                          <FieldError className="field-error" value={__(this.state.formErrors.first_name)} />
                        </div>

                        <div>
                          <IonItem lines="none" className="input-field-wrapper">
                            <IonInput
                              placeholder={__('Last name')}
                              onIonChange={(e) => this.handleInput('last_name', e.target.value)}
                              type="text"
                              pattern="text"
                              inputmode="text"
                              value={last_name}
                              selenium="lastName"
                            ></IonInput>
                          </IonItem>
                          <FieldError className="field-error" value={__(this.state.formErrors.last_name)} />
                        </div>
                      </div>
                    )}

                    <div className="input-field-container">
                      <StrongText className="primary-color">{__('Your email address')}</StrongText>
                      <IonItem lines="none" className="input-field-wrapper">
                        <IonInput
                          clearInput
                          className="okx-font-secondary"
                          placeholder={__('Email address')}
                          onIonInput={(e) => this.handleInput('email', e.target.value)}
                          onIonBlur={(e) => {
                            const usernameInput = document.querySelector(
                              'input[type="email"]:-webkit-autofill',
                            );
                            if (usernameInput) {
                              this.handleInput('email', usernameInput.value);
                            }
                            this.handleInput('email', e.target.value);
                          }}
                          type="email"
                          pattern="email"
                          inputmode="email"
                          value={email}
                        ></IonInput>
                      </IonItem>
                      <FieldError className="field-error" value={__(this.state.formErrors.email)} />
                    </div>
                    <div className="input-field-container input-field-container-multiple">
                      <StrongText className="primary-color">{__('Set a password')}</StrongText>
                      <IonItem lines="none" className="input-field-wrapper">
                        <PasswordInput
                          placeholder={__('Password')}
                          onIonChange={(e) => this.handleInput('password', e.target.value)}
                          value={password}
                        />
                      </IonItem>
                      <FieldError
                        className="field-error"
                        value={__(this.state.formErrors.password)}
                      />
                      <IonItem lines="none" className="input-field-wrapper">
                        <PasswordInput
                          placeholder={__('Re-enter password')}
                          onIonChange={(e) => this.handleInput('repeatPassword', e.target.value)}
                          value={repeatPassword}
                        />
                      </IonItem>
                      <FieldError
                        className="field-error"
                        value={__(repeatPassword !== password ? "Passwords are not same" : "")}
                      />
                    </div>
                    {getConfig().flags.hasMobileOnRegister &&
                      <>
                        {getConfig().flags.hasMobileCountryCode ? (
                          <div className="input-field-container">
                            <StrongText className="primary-color">{__('Mobile Number')}</StrongText>

                            <MobileInput mobile_code={mobile_code} mobile_value={mobile_value} setMobileCode={this.setMobileCode} setMobileValue={this.setMobileValue} />
                            {(this.state.formErrors.mobile || this.state.formErrors.mobile_value) && (
                              <FieldError className="field-error" value={__(this.state.formErrors.mobile || this.state.formErrors.mobile_value)} />
                            )}
                          </div>
                        ) : (
                          <div className="input-field-container">
                            <StrongText className="primary-color">{__('Mobile')}</StrongText>

                            <IonItem lines="none" className="input-field-wrapper">
                              <IonInput
                                placeholder={__('Mobile number')}
                                onIonChange={(e) => this.handleInput('mobile', e.target.value)}
                                required={true}
                                type="tel"
                                pattern="tel"
                                inputmode="tel"
                                onKeyPress={(e) => {
                                  const reqexFormat = mobile ? '^[0-9]+$' : '^[+0-9]+$';
                                  let regex = new RegExp(reqexFormat);
                                  if (regex.test(e.key)) {
                                    return true;
                                  }
                                  e.preventDefault();
                                  return false;
                                }}
                                value={mobile}
                              ></IonInput>
                            </IonItem>
                          </div>
                        )}


                      </>
                    }
                    {getConfig().restrictions?.min_registration_age ? (
                      <>
                        <div className="input-field-container">
                          <StrongText className="primary-color">{__('Date of birth')}</StrongText>
                          <IonItem lines="none" className="input-field-wrapper">
                            <DatePicker
                              placeholder={__('Date of birth')}
                              className="data-picker-input"
                              display="bottom"
                              max={yesterday}
                              defaultValue={defaultDateValue}
                              setText={__('Done')}
                              cancelText={__('Cancel')}
                              value={birthday}
                              onSet={(e, a) => this.handleInput('birthday', a.element.value)}
                              dateFormat="dd/mm/yy"
                            />
                          </IonItem>
                          <FieldError
                            className="field-error"
                            value={__(this.state.formErrors.birthday)}
                          />
                          <NormalText className='date-of-birth-description'>{__(`You must be over ${getConfig().restrictions?.min_registration_age} years old to register`)}</NormalText>
                        </div>


                      </>
                    ) : null}
                    {getConfig().flags.hasReferralCodeOnRegister ? (
                      <>
                        <div className="input-field-container">
                          <StrongText className="primary-color">{__('Referral code')}</StrongText>
                          <IonItem lines="none" className="input-field-wrapper">
                            <IonInput
                              placeholder={__('Referral code')}
                              onIonChange={(e) => this.handleInput('referral_code', e.target.value)}
                              onIonBlur={(e) => {
                                const usernameInput = document.querySelector('input[type="text"]:-webkit-autofill');
                                if (usernameInput) {
                                  this.handleInput('referral_code', usernameInput.value);
                                }
                                this.handleInput('referral_code', e.target.value);
                              }}
                              type="text"
                              pattern="text"
                              inputmode="text"
                              value={referral_code}
                              selenium="referralCode"
                            ></IonInput>
                          </IonItem>
                          <FieldError className="field-error" value={__(this.state.formErrors.birthday)} />
                        </div>

                      </>
                    ) : null}
                    {hasGenderSelector ? (
                      <>
                        <div className="input-field-container">
                          <StrongText className="primary-color">{`${__('Gender')} (${__('optional')})`}</StrongText>
                          <IonItem lines="none" className="input-field-wrapper dropdown-field">
                            <SelectOption
                              cssClass="language-picker"
                              display="center"
                              onSet={(event, data) => this.handleInput('gender', data.getVal())}
                              data={genderList}
                              label="Location"
                              value={gender}
                              inputStyle="box"
                              placeholder={__('Select one')}
                              cancelText={__('Cancel')}
                              setText={__('OK')}
                            />
                          </IonItem>
                        </div>


                      </>
                    ) : null}
                    {getConfig().flags.hasLanguageSelectionOnRegister && <>
                      {languages.length <= 1 ? null : (
                        <>

                          <div className="input-field-container">
                            <StrongText className="primary-color">{__('Language')}</StrongText>
                            <IonItem lines="none" className="input-field-wrapper dropdown-field">
                              <SelectOption
                                cssClass="language-picker"
                                display="center"
                                onSet={(e, data) => this.handleInput('locale', data.getVal())}
                                data={this.formatDataForSelect(getConfig().localization.supportedLocales)}
                                label="Location"
                                value={locale}
                                inputStyle="box"
                                placeholder={__('Select one')}
                                cancelText={__('Cancel')}
                                setText={__('OK')}
                              />
                            </IonItem>
                          </div>


                        </>
                      )}

                    </>}
                    {hasDietaryRequirements && <StrongText className='primary-color'>{`${__('Dietary requirements')} (${__('optional')})`}</StrongText>}
                    {hasDietaryRequirements ?
                      dietaryRequirementsList.map((dietaryReq, index) => (
                        <IonItem lines="none" key={index}>
                          <div tabIndex="-1"></div>
                          <IonCheckbox

                            slot="start"
                            checked={dietaryRequirements.includes(dietaryReq.value)}
                            onIonChange={(e) => {
                              if (e.detail.checked && !dietaryRequirements.includes(dietaryReq.value)) {
                                dietaryRequirements.push(dietaryReq.value);
                                this.setState({ dietaryRequirements });
                                this.handleInput('dietary_requirements', dietaryRequirements);
                              } else if (!e.detail.checked) {
                                this.setState({ dietaryRequirements: dietaryRequirements.filter(e => e !== dietaryReq.value) });
                                this.handleInput('dietary_requirements', dietaryRequirements.filter(e => e !== dietaryReq.value));
                              }
                            }}
                          />
                          <IonLabel className="ion-text-wrap">
                            <NormalText>
                              {__(dietaryReq.text)}
                            </NormalText>
                          </IonLabel>
                        </IonItem>
                      )) : null
                    }

                  </IonList>
                  <div className="no-padding">
                    <IonList>
                      <div lines="none">
                        <div tabIndex="-1"></div>
                        <div className="toggle">
                          <IonLabel>
                            <StrongText className>{__('Terms & conditions')}</StrongText>

                            <div className="register-terms-wrapper">
                              <IonCheckbox
                                color='secondary'
                                checked={accepted_terms_and_conditions}
                                onIonChange={(e) =>
                                  this.handleInput(
                                    'accepted_terms_and_conditions',
                                    e.detail.checked,
                                  )
                                }
                              />
                              <IonLabel className="ion-text-wrap">
                                <NormalText color="primary">
                                  {__('By signing up you agree to') + ' '}{' '}
                                  <span
                                    className="pointer underlined"
                                    onClick={() => forwardTo('/terms', { fromRegisterPage: true })}
                                  >
                                    {__('our terms and conditions')}
                                  </span>{' '}
                                  {__('and')}{' '}
                                  <span
                                    className="pointer underlined"
                                    onClick={() =>
                                      forwardTo('/privacy', { fromRegisterPage: true })
                                    }
                                  >
                                    {__('privacy policy')}
                                  </span>
                                </NormalText>
                              </IonLabel>
                            </div>
                          </IonLabel>
                          <FieldError
                            className="field-error"
                            value={__(this.state.formErrors.accepted_terms_and_conditions)}
                          />
                        </div>
                      </div>
                      <div lines="none">
                        <div tabIndex="-1"></div>
                        <div className="toggle">
                          <IonLabel>
                            <div className="register-terms-wrapper">
                              <IonCheckbox
                                color='secondary'
                                checked={is_subscribed}
                                onIonChange={(e) =>
                                  this.handleInput('is_subscribed', e.detail.checked)
                                }
                              />
                              <IonLabel className="ion-text-wrap">
                                <NormalText color="primary">
                                  {__(
                                    "I'd like to receive email updates that contain news, offers and promotions",
                                  )}
                                </NormalText>
                              </IonLabel>
                            </div>
                          </IonLabel>
                        </div>
                      </div>
                    </IonList>
                  </div>
                  <Spacer />
                  <IonButton
                    expand="block"
                    color="primary"
                    className="register-button uppercase okx-font-secondary"
                    onClick={() => this.handleRegister()}
                  >
                    {__('Sign up')}
                  </IonButton>
                </>
              ) : (
                <>
                  <IonList>
                    {getConfig().flags.hasFullNameOnRegister && (
                      <div className="input-field-container input-field-container-multiple">
                        <StrongText className="primary-color">{__('Your name')}</StrongText>
                        <div>
                          <IonItem lines="none" className="input-field-wrapper">
                            <IonInput
                              placeholder={__('First name')}
                              onIonChange={(e) => this.handleInput('first_name', e.target.value)}
                              type="text"
                              pattern="text"
                              inputmode="text"
                              value={first_name}
                              selenium="firstName"
                            ></IonInput>
                          </IonItem>
                          <FieldError className="field-error" value={__(this.state.formErrors.first_name)} />
                        </div>

                        <div>
                          <IonItem lines="none" className="input-field-wrapper">
                            <IonInput
                              placeholder={__('Last name')}
                              onIonChange={(e) => this.handleInput('last_name', e.target.value)}
                              type="text"
                              pattern="text"
                              inputmode="text"
                              value={last_name}
                              selenium="lastName"
                            ></IonInput>
                          </IonItem>
                          <FieldError className="field-error" value={__(this.state.formErrors.last_name)} />
                        </div>
                      </div>
                    )}

                    {getConfig().restrictions?.min_registration_age ? (
                      <div className="input-field-container">
                        <StrongText className="primary-color">{__('Your date of birth')}</StrongText>
                        <IonItem lines="none" className="input-field-wrapper">
                          <DatePicker
                            placeholder={__('Date of birth')}
                            className="data-picker-input"
                            display="bottom"
                            max={yesterday}
                            defaultValue={defaultDateValue}
                            setText={__('Done')}
                            cancelText={__('Cancel')}
                            value={birthday}
                            onSet={(e, a) => this.handleInput('birthday', a.element.value)}
                            dateFormat="dd/mm/yy"
                            selenium="datePicker"
                          />
                        </IonItem>
                        <FieldError className="field-error" value={__(this.state.formErrors.birthday)} />
                      </div>
                    ) : null}
                  </IonList>

                  <div className="no-padding">
                    <IonList>
                      <div lines="none">
                        <div tabIndex="-1"></div>
                        <div className="toggle">
                          <IonLabel>
                            <StrongText>{__('Terms & conditions')}</StrongText>

                            <div className="register-terms-wrapper">
                              <IonCheckbox
                                color="secondary"
                                checked={accepted_terms_and_conditions}
                                onIonChange={(e) =>
                                  this.handleInput(
                                    'accepted_terms_and_conditions',
                                    e.detail.checked,
                                  )
                                }
                              />
                              <IonLabel className="ion-text-wrap">
                                <NormalText color="primary">
                                  {__('By signing up you agree to') + ' '}{' '}
                                  <span
                                    className="pointer underlined primary-color"
                                    onClick={() => forwardTo('/terms', { fromRegisterPage: true })}
                                  >
                                    {__('our terms and conditions')}
                                  </span>{' '}
                                  {__('and')}{' '}
                                  <span
                                    className="primary-color  pointer underlined"
                                    onClick={() =>
                                      forwardTo('/privacy', { fromRegisterPage: true })
                                    }
                                  >
                                    {__('privacy policy')}
                                  </span>
                                </NormalText>
                              </IonLabel>
                            </div>
                          </IonLabel>
                          <FieldError
                            className="field-error"
                            value={__(this.state.formErrors.accepted_terms_and_conditions)}
                          />
                        </div>
                      </div>
                      <div lines="none">
                        <div tabIndex="-1"></div>
                        <div className="toggle">
                          <IonLabel>
                            <div className="register-terms-wrapper">
                              <IonCheckbox
                                color="secondary"
                                checked={is_subscribed}
                                onIonChange={(e) =>
                                  this.handleInput('is_subscribed', e.detail.checked)
                                }
                              />
                              <IonLabel className="ion-text-wrap">
                                <NormalText color="primary">
                                  {__(
                                    "I'd like to receive email updates that contain news, offers and promotions",
                                  )}
                                </NormalText>
                              </IonLabel>
                            </div>
                          </IonLabel>
                        </div>
                      </div>
                    </IonList>
                  </div>
                  <div className="top-medium">
                    {hasSocialLogin && (
                      <>
                        <SocialLogin
                          isRegister={true}
                          validateForm={() => this.validateSocialForm()}
                        />

                        <BlockSeparator text='or' />
                      </>
                    )}
                    <IonButton
                      expand="block"
                      color='secondary'
                      fill="clear"
                      className="link underlined"
                      onClick={() => this.setState({ registerWithEmail: true })}
                    >
                      {__('Sign up with email')}
                    </IonButton>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="absolute-content flex-row-wrapper">
              <Title>{__('About you')}</Title>
              <NormalText className="block">{__('Tell us some more details (optional)')}</NormalText>
              <Spacer size={1} />

              <IonList>
                {!getConfig().flags.hasFullNameOnRegister && (
                  <>
                    <div className="input-field-container input-field-container-multiple">
                      <StrongText className="primary-color">{__('Your name')}</StrongText>
                      <IonItem lines="none" className="input-field-wrapper">
                        <IonInput
                          placeholder={__('First name')}
                          onIonChange={(e) => this.handleInput('first_name', e.target.value)}
                          required={true}
                          type="text"
                          pattern="text"
                          inputmode="text"
                          value={first_name}
                        ></IonInput>
                      </IonItem>
                      <IonItem lines="none" className="input-field-wrapper">
                        <IonInput
                          placeholder={__('Last name')}
                          onIonChange={(e) => this.handleInput('last_name', e.target.value)}
                          required={true}
                          type="text"
                          pattern="text"
                          inputmode="text"
                          value={last_name}
                        ></IonInput>
                      </IonItem>
                    </div>

                  </>
                )}
                <>
                  {getConfig().flags.hasMobileCountryCode ? (
                    <>
                      <div className="input-field-container">
                        <StrongText className="primary-color">{__('Your mobile number')}</StrongText>

                        <MobileInput mobile_code={mobile_code} mobile_value={mobile_value} setMobileCode={this.setMobileCode} setMobileValue={this.setMobileValue} />
                        {(this.state.formErrors.mobile || this.state.formErrors.mobile_value) && (
                          <FieldError className="field-error" value={__(this.state.formErrors.mobile || this.state.formErrors.mobile_value)} />
                        )}
                      </div>

                    </>

                  ) : (
                    <>
                      <div className="input-field-container">
                        <StrongText className="primary-color">{__('Your mobile number')}</StrongText>
                        <IonItem lines="none" className="input-field-wrapper">
                          <IonInput
                            placeholder={__('Enter your mobile number')}
                            onIonChange={(e) => this.handleInput('mobile', e.target.value)}
                            required={true}
                            type="tel"
                            pattern="tel"
                            inputmode="tel"
                            onKeyPress={(e) => {
                              const reqexFormat = mobile ? '^[0-9]+$' : '^[+0-9]+$';
                              let regex = new RegExp(reqexFormat);
                              if (regex.test(e.key)) {
                                return true;
                              }
                              e.preventDefault();
                              return false;
                            }}
                            value={mobile}
                          ></IonInput>
                        </IonItem>
                      </div>

                    </>
                  )}
                </>
                {!getConfig().restrictions?.min_registration_age && (
                  <div className='input-field-container'>
                    <StrongText className="primary-color">{__('Your date of birth')}</StrongText>
                    <IonItem lines="none" className="input-field-wrapper">
                      <DatePicker
                        placeholder={__('Date of birth')}
                        className="data-picker-input"
                        display="bottom"
                        max={yesterday}
                        defaultValue={defaultDateValue}
                        setText={__('Done')}
                        cancelText={__('Cancel')}
                        value={birthday}
                        onSet={(e, a) => this.handleInput('birthday', a.element.value)}
                        dateFormat="dd/mm/yy"
                      />
                    </IonItem>
                  </div>
                )}

                {getConfig().flags.hasRestaurantPreferenceSelector &&
                  <div className="input-field-container">
                    <StrongText className="primary-color">{__('Local House')}</StrongText>
                    <IonItem lines="none" className="input-field-wrapper dropdown-field">
                      <SelectOption
                        cssClass="language-picker"
                        display="center"
                        onSet={(event, data) => this.handleInput('local_house', data.getVal())}
                        data={[{value:'',text:''},...this.props.restaurants.map(el=>({value:el.name,text:el.name}))]}
                        label="Location"
                        value={local_house}
                        inputStyle="box"
                        placeholder={__('Select your local house')}
                        cancelText={__('Catncel')}
                        setText={__('OK')}
                      />
                    </IonItem>
                  </div>

                }
                {getConfig().flags.hasPostalCodeSelector &&
                  <div className='input-field-container'>
                    <StrongText>{__('Postcode')}</StrongText>
                    <IonItem lines="none" className="input-field-wrapper">
                      <IonInput
                        placeholder={__('Postcode')}
                        onIonChange={(e) => this.handleInput('postal_code', e.target.value)}
                        type="text"
                        pattern="text"
                        inputmode="text"
                        value={postal_code}
                      ></IonInput>
                    </IonItem>
                  </div>

                }
              </IonList>
              <Spacer size={2} />
              <div className="flex-min">
                <IonButton
                  expand="block"
                  color="primary"
                  onClick={() => {
                    const profile = {
                      first_name,
                      last_name,
                      mobile,
                      birthday,
                      is_subscribed,
                      mobile_code,
                      mobile_value,
                      local_house,
                      postal_code
                    };
                    this.props.dispatch(updateProfile(profile));
                    forwardTo(getDefaultRoute(this.props.navConfig).path, {
                      fromLogin: this.state.fromGiftVoucher,
                    });
                    dispatch({
                      type: CLEAR_REGISTER_FORM,
                    });
                  }}
                >
                  {__('Continue')}
                </IonButton>
                <IonButton
                  expand="block"
                  fill="clear"
                  color="secondary"
                  className=" underlined link transparent"
                  onClick={() => {
                    forwardTo(getDefaultRoute(this.props.navConfig).path, {
                      fromLogin: this.state.fromGiftVoucher,
                    });
                    dispatch({
                      type: CLEAR_REGISTER_FORM,
                    });
                  }}
                >
                  {__('Skip for now')}
                </IonButton>
              </div>
            </div>
          )}
        </Layout>
        <IonAlert
          isOpen={isRegisterModalOpen}
          onDidDismiss={() => this.props.dispatch(setModal('isRegisterModalOpen', false))}
          header={__('Success')}
          message={__('Register processed. Please check your mail')}
          buttons={[
            {
              text: __('Close'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.props.dispatch(setModal(('isRegisterModalOpen', false))),
            },
          ]}
        />
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, isRegisterModalOpen, registerFormData } = state.profile;
  const { navConfig } = state.common;
  const { restaurants } = state.restaurants
  return {
    auth,
    isRegisterModalOpen,
    registerFormData,
    navConfig,
    restaurants
  };
};

export default connect(stateToProps)(withTranslation(Register));
