import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import './index.css';
import ApplyVouchers from '../applyVouchers';

class ApplyLoyalty extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		const { __ } = this.props;

		return (
			<Layout headerWithTitle color="transparent" title={__('Apply a voucher')} backHandler={this.backHandler} scrollY={false} >
				<div className="absolute-content scrollable-y">
					<ApplyVouchers></ApplyVouchers>
				</div>
			</Layout>
		);
	}
}

const stateToProps = (state) => {
	return {
		transactionHistory: state.orders.history || [],
		orderHistory: state.orders.orderHistory || [],
		profile: state.profile.profile,
	};
};

export default withRouter(withTranslation(connect(stateToProps)(ApplyLoyalty)));
