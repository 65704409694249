import React from 'react';
import { AdvancedMarker, APIProvider, Map } from '@vis.gl/react-google-maps';
import { getConfig } from '../../appConfig';

const mapStyles = {
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  map: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
};

export class MapWrapper extends React.Component {
  constructor(props) {
    super(props);

    if (!props.hasOwnProperty('google')) {
      throw new Error('You must include a `google` prop');
    }

    this.listeners = {};
    this.state = {
      currentLocation: this.props.selectedLocation,
      mapConfig:{
        defaultCenter: { lat: 37.774929, lng: -122.419416 },
        defaultZoom: 10,
        reuseMaps: true,
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedLocation !== this.props.selectedLocation) {
      this.changeLocation(this.props.selectedLocation);
    }
  }

  handleCameraChange(ev){
    const { mapConfig } = this.state;
    this.setState({mapConfig: {...mapConfig, center: ev.detail.center,zoom: ev.detail.zoom}});
  }

  changeLocation(location){
    const {zoom, latitudeOffset, longitudeOffset } = this.props;
    const { mapConfig } = this.state;
    this.setState({
      mapConfig: {
        ...mapConfig, 
        center: { lat: parseFloat(location.latitude) - (latitudeOffset || 0),  lng: parseFloat(location.longitude) - (longitudeOffset || 0) },
        zoom: zoom || 10
      }});
  }

  componentDidMount() {
    const {
      zoom,
      clickableIcons,
      disableDefaultUI,
      zoomControl,
      zoomControlOptions,
      mapTypeControl,
      mapTypeControlOptions,
      scaleControl,
      streetViewControl,
      streetViewControlOptions,
      panControl,
      rotateControl,
      fullscreenControl,
      scrollwheel,
      draggable,
      draggableCursor,
      keyboardShortcuts,
      disableDoubleClickZoom,
      noClear,
      styles,
      gestureHandling,
      locations,
      selectedLocation,
      latitudeOffset, 
      longitudeOffset  
    } = this.props;
    let position = { lat: 0, lng: 0 };
    if(locations && locations.length > 0) {
      const location = selectedLocation || locations[0];
      position = { lat: parseFloat(location.latitude) - (latitudeOffset || 0),  lng: parseFloat(location.longitude) - (longitudeOffset || 0) };
    }
    const mapConfig = {
      defaultCenter: position,
      center: position,
      defaultZoom: zoom || 10,
      clickableIcons: !!clickableIcons,
      disableDefaultUI: disableDefaultUI,
      zoomControl: zoomControl,
      zoomControlOptions: zoomControlOptions,
      mapTypeControl: mapTypeControl,
      mapTypeControlOptions: mapTypeControlOptions,
      scaleControl: scaleControl,
      streetViewControl: streetViewControl,
      streetViewControlOptions: streetViewControlOptions,
      panControl: panControl,
      rotateControl: rotateControl,
      fullscreenControl: fullscreenControl,
      scrollwheel: scrollwheel,
      draggable: draggable,
      draggableCursor: draggableCursor,
      keyboardShortcuts: keyboardShortcuts,
      disableDoubleClickZoom: disableDoubleClickZoom,
      noClear: noClear,
      styles: styles,
      gestureHandling: gestureHandling,
      reuseMaps: true,
    };
    this.setState({
      mapConfig: mapConfig
    });
  }

  render() {
    const {
      containerStyle,
      className,
      locations,
      setLocation
    } = this.props;
    const { mapConfig } = this.state;
    const containerStyles = Object.assign({}, mapStyles.container, containerStyle);

    return (
      <div style={containerStyles} className={className}>
        <APIProvider apiKey={getConfig().services.google_maps.google_api_key}>
          <Map 
            mapId="map_id" {...mapConfig}
            onCameraChanged={(event) => this.handleCameraChange(event)}
          >
            {(locations || []).map((store, index) => (
              <AdvancedMarker
                key={index}
                position={{ lat: parseFloat(store.latitude), lng: parseFloat(store.longitude) }}
                onClick={() => {
                  if(setLocation){
                    setLocation(store);
                  }
                }}
              />
            ))}
          </Map>
        </APIProvider>
      </div>
    );
  }
}

export default MapWrapper;
