import React from 'react';
import './index.css';
import { IonIcon } from '@ionic/react';
import { chevronForward } from 'ionicons/icons';

const DashboardBar = ({ onDismiss, handleClick, children }) => (
	<div className="dashboard-bar-wrapper">
		<div className="dashboard-scrim" onClick={onDismiss ? onDismiss : null}></div>
		<div className="dashboard-bar  box-wrapper" onClick={handleClick}>
			{children}
			<IonIcon icon={chevronForward}  />

		</div>
	</div>
);

export default DashboardBar;
