import React from 'react';
import { connect } from 'react-redux';
import { IonItem, IonCol, IonGrid, IonRow, IonInput, IonButton } from '@ionic/react';
import Basket from '../../lib/basket';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { NormalText, Spacer, StrongText, Subtitle } from '../../components/common';
import './index.css';
import Loading from '../../components/spinner';
import TableItemsOverview from '../../components/tableItemsOverview';
import TableBillServiceCharge from '../../components/tableBillServiceCharge';
import { forwardTo, isEmptyObject } from '../../lib/utils';
import BillPayAmount from '../../components/billPayAmount';
import CheckoutPay from '../checkout/CheckoutPay';


class SplitBillByAmount extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			serviceChargeValue: Basket.bill_pay_service_charge || 0,
			amountToPay: (Basket.amount_left_to_pay / 100).toFixed(2) || 0,
		};
	}

	onServiceChargeSetHandler = (serviceChargeValue) => {
		this.setState({ serviceChargeValue });
	};
	componentDidMount() {
		if (isEmptyObject(Basket.bill_data)) {
			Basket.reset();
			forwardTo('/table-bill-pay');
			return;
		}
		Basket.setTablePaymentAmount(Basket.amount_left_to_pay);
		if (isEmptyObject(Basket.getRestaurant()) || !Basket.getRestaurant()) {
			const selectedRestaurant = this.props.restaurants.find((restaurant) => restaurant.name == Basket.bill_data.restaurant_name);
			Basket.setRestaurant(selectedRestaurant);
		}
	}
	componentDidUpdate(prevProps) {
		if (this.props.tableData.total_due !== prevProps.tableData.total_due) {
			Basket.setBillPayData(this.props.tableData);
			Basket.setTablePaymentAmount(this.props.tableData.total_due);
			this.setState({ amountToPay: (this.props.tableData.total_due / 100).toFixed(2) || 0 });
		}
	}
	setAmountToPay = (value) => {
		this.setState({ amountToPay: value }, () => {
			if (value > Basket.amount_left_to_pay / 100) {
				Basket.setTablePaymentAmount(Basket.amount_left_to_pay);
				this.setState({ amountHigher: true, amountToPay: value });
			} else {
				Basket.setTablePaymentAmount(value * 100);
				Basket.setBillPayServiceCharge(this.state.serviceChargeValue);

				this.setState({ amountHigher: false, amountToPay: value });
			}
		});
	};
	backHandler = () => {
		Basket.reset();
		forwardTo('/table-bill-pay');
	};
	render() {
		const { __, clientSecret, location, dispatch, profile, isTableItemsOverviewVisible } = this.props;
		const { serviceChargeValue } = this.state;
		const deliveryOption = Basket.getDeliveryOption();

		return (
			<Loading>
				<Layout backHandler={this.backHandler} headerWithTitle title={`${__('Table')} ${Basket.getBillTableNumber()}`} color="transparent">
					<div className="absolute-content scrollable-y">
						<div className="bill-pay-title-wrapper">
							<NormalText className="block">
								{__('Table')} {Basket.getBillTableNumber()}
							</NormalText>
							<IonButton fill="clear" color="secondary" className="link underlined" onClick={() => forwardTo('/table-bill-pay')}>
								{__('Wrong table?')}
							</IonButton>
						</div>
						<Spacer size={1} />
						<BillPayAmount />
						<Spacer size={1} />
						<TableItemsOverview />

						<Spacer size={1} />
						<div className="custom-amount-selector-wrapper">
							<NormalText>{__('Amount to pay')}</NormalText>
							<div className="box-wrapper  inline-box-wrapper">
								<div>
									<StrongText>{Basket.getCurrency().label}</StrongText>
								</div>
								<IonItem lines="none" className="custom-amount-field">
									<IonInput
										onIonChange={(e) => this.setAmountToPay(e.target.value)}
										max={Basket.amount_left_to_pay / 100}
										min="0"
										required={true}
										type="number"
										pattern="decimal"
										inputmode="decimal"
										value={this.state.amountToPay}
									/>
								</IonItem>
							</div>
						</div>

						<Spacer size={1} />
						<TableBillServiceCharge amountToPay={this.state.amountToPay} serviceChargeSet={this.onServiceChargeSetHandler} serviceChargeVal={Basket.bill_pay_service_charge_applied} />
						<Spacer size={1} />
						<>
							<IonGrid className="box-wrapper space-between pointer ">
								<IonRow>
									<IonCol>
										<Subtitle className="bold">{__('Payment amount')}</Subtitle>
									</IonCol>
									<IonCol className="righted">
										<Subtitle className="bold">{Basket.getTotalPlusServiceCharge()}</Subtitle>
									</IonCol>
								</IonRow>
							</IonGrid>
							<Spacer size={1} />
						</>
						{Basket.table_payment_amount > 0 && (
							<CheckoutPay clientSecret={clientSecret} dispatch={dispatch} __={__} location={location} profile={profile} isBillPay={true} billTotal={Basket.bill_pay_total} />
						)}
						<Spacer size={1} />
						<IonButton disabled={Basket.table_payment_amount == 0} color="primary" expand="block" onClick={() => forwardTo('/checkout', { fromTablePay: true })}>
							{__('Pay with card')}
						</IonButton>
					</div>
				</Layout>
			</Loading>
		);
	}
}

const mapStateToProps = (store) => {
	const { restaurants, tableNumbers, tableData } = store.restaurants;
	const { myLocation, isTableItemsOverviewVisible } = store.common;
	const { clientSecret } = store.orders;
	const { auth, profile } = store.profile;

	return {
		restaurants: restaurants || [],
		tableNumbers: tableNumbers || {},
		myLocation,
		profile,
		clientSecret,
		auth,
		isTableItemsOverviewVisible,
		tableData,
	};
};

export default connect(mapStateToProps)(withTranslation(SplitBillByAmount));
