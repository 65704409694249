import React from 'react';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import { Spacer, Title } from '../../components/common';
import { IonButton } from '@ionic/react';
import { forwardTo, getDefaultRoute, isWebConfig } from '../../lib/utils';
import { connect } from 'react-redux';

class NotFound extends React.Component {
	goBack = () => {
		const defaultRoute = getDefaultRoute(this.props.navConfig);
		isWebConfig() ? forwardTo(defaultRoute.path) : forwardTo('/dashboard');
	};

	getNotFoundText = (path) => {
		const messages = {
			'/delivery': 'Delivery is currently unavailable',
			'/click-and-collect': 'Click & Collect is currently unavailable',
			'/order-to-table': 'Order to table is currently unavailable',
			'/scheduled-collection': 'Scheduled collection is currently unavailable',
			'/pick-up-at-counter': 'Pick up at counter is currently unavailable',
			'/scheduled-delivery': 'Scheduled delivery is currently unavailable',
			'/browse-menu': 'Browse menu is currently unavailable',
		};

		return messages[path] || 'The page you’re looking for isn’t available right now.';
	};
	render() {
		const { __ } = this.props;
		return (
			<Loading>
				<Layout showHamburger headerTitle={__('Not found')} color="transparent">
					<div style={{ textAlign: 'center' }}>
						<Title>{__('Opps')}</Title>
						<Title>{__(this.getNotFoundText(this.props.location.pathname))}</Title>
						<Spacer size={1} />
						<IonButton onClick={() => this.goBack()}>{__('Go home')}</IonButton>
					</div>
				</Layout>
			</Loading>
		);
	}
}
const stateToProps = (state) => {
	const { navConfig } = state.common;
	return {
		navConfig,
	};
};

export default connect(stateToProps)(withTranslation(NotFound));
