export const GET_RESTAURANTS = 'GET_RESTAURANTS';
export const SET_RESTAURANT_PROP = 'SET_RESTAURANT_PROP';
export const GET_REWARDS = 'GET_REWARDS';
export const GET_IKENTOO_MENU = 'GET_IKENTOO_MENU';
export const BEFORE_SHOW_TIME_PICKER = 'BEFORE_SHOW_TIME_PICKER';
export const BEFORE_CLOSE_TIME_PICKER = 'BEFORE_CLOSE_TIME_PICKER';
export const GET_IKENTOO_MENUS_FOR_LOCATION = 'GET_IKENTOO_MENUS_FOR_LOCATION';
export const GET_RESTAURANTS_SNOOZED_DATA = 'GET_RESTAURANTS_SNOOZED_DATA';
export const CLEAR_IKENOO_MENU = 'CLEAR_IKENOO_MENU';
export const PASSED_ORDER = 'PASSED_ORDER';
export const SAVE_DELIVERY_DATA_TEMPORARILY = 'SAVE_DELIVERY_DATA_TEMPORARILY';
export const GET_TABLE_NUMBER = 'GET_TABLE_NUMBER';
export const SET_TABLE_NUMBERS = 'SET_TABLE_NUMBERS';
export const GET_RESTAURANT_TABLE = 'GET_RESTAURANT_TABLE';
export const SET_TABLE_DATA = 'SET_TABLE_DATA';
export const GET_TABLE_DATA = 'GET_TABLE_DATA';
export const GET_TABLE_BY_ID = 'GET_TABLE_BY_ID';
export const GET_IKENTOO_MENUS_PICK_UP_AT_COUNTER = 'GET_IKENTOO_MENUS_PICK_UP_AT_COUNTER';
export const GET_TAX = 'GET_TAX';
export const GET_TAX_DATA = 'GET_TAX_DATA';

