import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import React, { Component } from 'react';
import Basket from '../../lib/basket.js';
import { withTranslation } from '../../lib/translate.js';
import { NormalText, StrongText, Subtitle } from '../common/index.js';
import { chevronDown, chevronUp } from 'ionicons/icons';

import './index.css';
import { connect } from 'react-redux';

class BillPayAmount extends Component {
	constructor(props) {
		super(props);
		this.state = {
			historyVisible: false,
			tableData: {},
		};
	}
	componentDidUpdate(prevProps) {
		if (this.props.tableData.total_due !== prevProps.tableData.total_due) {
			this.setState({ tableData: this.props.tableData });
		}
	}
	render() {
		const { __, isTableItemsOverviewVisible } = this.props;
		const { historyVisible } = this.state;
		return (
			<IonGrid
				className={`payment-amount-wrapper box-wrapper space-between ${Basket.bill_data.total_paid > 0 ? 'box-wrapper-list' : ''} ${
					isTableItemsOverviewVisible ? 'items-overwiew-opened' : ''
				}`}
			>
				<IonRow>
					<IonCol>
						<Subtitle className="bold ">{__('Bill total')}</Subtitle>
					</IonCol>
					<IonCol className="righted">
						<Subtitle className="bold ">{Basket.getBillTotal()}</Subtitle>
					</IonCol>
				</IonRow>
				{Basket.bill_data.total_paid > 0 && (
					<>
						<IonRow className="payment-history-header pointer" onClick={() => this.setState({ historyVisible: !historyVisible })}>
							<IonCol>
								<div>
									{__('Already paid')}
									<IonIcon icon={historyVisible ? chevronUp : chevronDown}></IonIcon>
								</div>
							</IonCol>
							<IonCol className="righted">{!historyVisible && Basket.getPaidAmount()}</IonCol>
							{historyVisible && (
								<>
									{Basket.getPayEvents()
										.filter((el) => el.status == 'success' || el.status == 'payment_lines')
										.map((el) => (
											<IonRow className="payment-history-wrapper" style={{ width: '100%' }}>
												<IonCol size="8">
													<NormalText>
														{el.first_name == 'POS' && `${__('Manual Payment')}`}
														{el.first_name == 'guest_user' && `${__('Guest User')}`}
														{el.first_name !== 'guest_user' && el.first_name !== 'POS' && `${el.first_name}`}
													</NormalText>
												</IonCol>
												<IonCol size="4" className="righted">
													<NormalText>{Basket.formatPrice(el.paid_amount / 100, true)}</NormalText>
												</IonCol>
											</IonRow>
										))}
								</>
							)}
						</IonRow>

						<IonRow>
							<IonCol>
								<Subtitle className="bold">{__('Left to pay')}</Subtitle>
							</IonCol>
							<IonCol className="righted">
								<Subtitle className="bold">{Basket.getAmountLeftToPay()}</Subtitle>
							</IonCol>
						</IonRow>
					</>
				)}
			</IonGrid>
		);
	}
}
const stateToProps = (state) => {
	const { isTableItemsOverviewVisible } = state.common;
	const { tableData } = state.restaurants;
	return {
		isTableItemsOverviewVisible,
		tableData,
	};
};
export default connect(stateToProps)(withTranslation(BillPayAmount));
