import { IonAlert, IonButton, IonIcon, IonInput, IonItem } from '@ionic/react';
import { chevronForwardOutline, closeOutline, pencilOutline } from 'ionicons/icons';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getConfig } from '../../appConfig';
import { FieldError, NormalText, Sectiontitle, SmallText, Spacer, StrongText, Subtitle, Title } from '../../components/common';
import Incrementer from '../../components/incrementer';
import Layout from '../../components/layout';
import Modal from '../../components/modal';
import OrderContent from '../../components/orderContent';
import { PillGroup } from '../../components/pill';
import Loading from '../../components/spinner';
import Basket from '../../lib/basket';
import moment from '../../lib/moment';
import { withTranslation } from '../../lib/translate';
import { BigNumber, deepCopy, forwardTo, go, goBack, isDefined, isElementFullyVisible, isEmptyObject, isWebConfig, validateProfileData } from '../../lib/utils';
import { ApplyPoints } from '../../screens/applyPoints';
import { checkDiscount, getGiftCards, restoreAuth, sendVoucherCode, setCommonProp, setModal, setProtectedReferrer, showToast } from '../../store/actions';
import MobileInput from '../../components/mobileInput';
import CardList from '../../components/cardList';
import CheckoutPay from '../checkout/CheckoutPay';

import PointsReedemBox from '../../components/pointsReedemBox';
import Mobiscroll from '../../components/mobiscroll';
import { ReactComponent as LocationIcon } from '../../assets/images/distance.svg';
import { ReactComponent as TimeIcon } from '../../assets/images/acute.svg';
import { ReactComponent as TableIcon } from '../../assets/images/picnic-table.svg';
import { ReactComponent as EditIcon } from '../../assets/images/edit-icon.svg';
import { ReactComponent as DiscountIcon } from '../../assets/images/discount.svg';

const { SelectOption } = Mobiscroll;
import './index.css';
import { Capacitor } from '@capacitor/core';
import { GET_TAX } from '../../store/constants';

const isWeb = () => Capacitor.getPlatform() === 'web';
const referrer = isWebConfig() ? '/order' : '/order-summary';

const {
	getRestauranName,
	getOrderDate,
	getOrderTime,
	changeItemQuantity,
	itemsCount,
	getItems,
	setMobile,
	getMobile,
	getAllergen,
	getTotal,
	isMinimumOrderTotalSatisfied,
	getTableNumber,
	getASAP,
	getSelectedCard
} = Basket;

class OrderSummaryRaw extends React.Component {
	constructor(props) {
		super(props);
		this.voucherCodeInput = React.createRef()
		const profile = this.props.profile;
		if (!getMobile() && profile && profile.mobile) {
			setMobile(profile.mobile);
		}
		this.state = {
			quantityModal: null,
			allergenModal: { isOpen: false, fromCheckoutBtn: false },
			applyPointsModalOpen: false,
			mobile: getMobile() || '',
			email: profile && !profile?.is_guest && profile.email ? profile.email : '',
			customFieldValue: Basket.getCustomFieldValue()?.field_value || '',
			customFieldName: Basket.getCustomFieldValue()?.field_name || '',
			collectedModal: false,
			mobile_code: this.props.profile.mobile_code ? this.props.profile.mobile_code : null,
			mobile_value: this.props.profile.mobile_value ? this.props.profile.mobile_value : this.props.profile.mobile == this.props.profile.mobile_code ? '' : this.props.profile.mobile,
			openedGiftCards: [],
			applyVoucherCodeVisible: false
		};
	}

	componentDidMount() {
		if (this.props.auth.loggedIn) {
			this.props.dispatch(getGiftCards());
		}
	}
	componentDidUpdate(prevProps) {
		if (this.props.profile.mobile !== prevProps.profile.mobile) {
			this.setState({ mobile: this.props.profile.mobile });
		}
	}


	handleInput = (key, val) => {
		this.setState({ [key]: val });
		setMobile(val);
	};

	backHandler = () => {
		if (this.props.location && this.props.location.state && this.props.location.state.fromItemDetails) {
			// skip item details page when we going back from order summary
			go(-2);
		} else {
			goBack();
		}
	};
	handleYocoSubmit = (allergens = []) => {
		const { email, mobile } = this.state;

		const force_allergen = getConfig()?.flags ? getConfig().flags.force_allergen : false;
		if (allergens.length > 0 && force_allergen) {
			this.setState({ allergenModal: { isOpen: true, fromCheckoutBtn: true } });
			return;
		}
		Basket.setCustomFieldValue({
			field_value: this.state.customFieldValue,
			field_name: this.state.customFieldName,
		});
		const uuid = `${this.props.profile.id ?? 'guest'}_${Date.now()}`;
		Basket.setUUID(uuid);
		if (Basket.getOrderType() === 'Table') {
			const tableRequiredFields = getConfig().general.tableRequiredFields || [];
			if (tableRequiredFields?.length === 0 || !tableRequiredFields) {
				Basket.createYocoOrder('yoco');
			} else {
				let valid = true;
				for (let i = 0; i < tableRequiredFields.length; i++) {
					if (!this.props.profile[`${tableRequiredFields[i]}`]) {
						valid = false;
						break;
					}
				}
				!valid ? forwardTo('/contact-details', { email, mobile, referrer }) : Basket.createYocoOrder('yoco');
			}
		} else {
			if ((this.props.profile.mobile || Basket.getMobile()) && this.props.profile.first_name) {
				Basket.createYocoOrder('yoco');
				setMobile(this.props.profile.mobile);
			} else {
				forwardTo('/contact-details', { email, mobile, referrer });
			}
		}
	};
	handleOrderCreation = (action) => {
		const allergens = getAllergen() || [];

		const force_allergen = getConfig()?.flags ? getConfig().flags.force_allergen : false;
		if (allergens.length > 0 && force_allergen) {
			this.setState({ allergenModal: { isOpen: true, action } });
			return;
		} else {
			action()
		}
	}
	handleSubmit = (allergens = []) => {
		const { cards } = this.props;
		const { email, mobile } = this.state;
		const force_allergen = getConfig()?.flags ? getConfig().flags.force_allergen : false;
		if (!isMinimumOrderTotalSatisfied()) {
			// display toast with flag 'true'
			isMinimumOrderTotalSatisfied(true);
			return;
		}
		if (allergens.length > 0 && force_allergen) {
			this.setState({ allergenModal: { isOpen: true, fromCheckoutBtn: true } });
			return;
		}
		Basket.setCustomFieldValue({
			field_value: this.state.customFieldValue,
			field_name: this.state.customFieldName,
		});
		const uuid = `${this.props.profile.id ?? 'guest'}_${Date.now()}`;
		Basket.setUUID(uuid);
		if (getTotal() === 0) {
			if (Basket.getOrderType() === 'Table') {
				const tableRequiredFields = getConfig().general.tableRequiredFields || [];
				if (tableRequiredFields?.length === 0 || !tableRequiredFields) {
					this.props.auth.loggedIn ? Basket.createOrder() : forwardTo('/contact-details', { email, mobile });
				} else {
					let valid = true;
					for (let i = 0; i < tableRequiredFields.length; i++) {
						if (!this.props.profile[`${tableRequiredFields[i]}`]) {
							valid = false;
							break;
						}
					}
					!valid ? forwardTo('/contact-details', { email, mobile }) : Basket.createOrder();
				}
			} else {
				if (this.props.profile.mobile && this.props.profile.first_name) {
					Basket.createOrder();
				} else {
					forwardTo('/contact-details', { email, mobile });
				}
			}

			return;
		}
		if (cards && cards.length >= 1) {
			if (Basket.getOrderType() === 'Table') {
				const tableRequiredFields = getConfig().general.tableRequiredFields || [];
				if (tableRequiredFields?.length === 0 || !tableRequiredFields) {
					this.props.auth.loggedIn ? forwardTo('/checkout') : forwardTo('/contact-details', { email, mobile });
				} else {
					let valid = true;
					for (let i = 0; i < tableRequiredFields.length; i++) {
						if (!this.props.profile[`${tableRequiredFields[i]}`]) {
							valid = false;
							break;
						}
					}
					!valid ? forwardTo('/contact-details', { email, mobile }) : forwardTo('/checkout');
				}
			} else {
				if (this.props.profile.mobile && this.props.profile.first_name) {
					forwardTo('/checkout', {
						giftVoucher: Basket.getDeliveryOption().id === 'gift-vouchers' ? true : false,
					});
					setMobile(this.props.profile.mobile);
				} else {
					forwardTo('/contact-details', { email, mobile });
				}
			}
		} else {
			if (Basket.getOrderType() === 'Table') {
				const tableRequiredFields = getConfig().general.tableRequiredFields || [];
				if (tableRequiredFields?.length === 0 || !tableRequiredFields) {
					this.props.auth.loggedIn ? forwardTo('/checkout') : forwardTo('/contact-details', { email, mobile });
				} else {
					let valid = true;
					for (let i = 0; i < tableRequiredFields.length; i++) {
						if (!this.props.profile[`${tableRequiredFields[i]}`]) {
							valid = false;
							break;
						}
					}
					!valid ? forwardTo('/contact-details') : forwardTo('/checkout');
				}
			} else {
				if (this.props.profile.mobile && this.props.profile.first_name) {
					setMobile(this.props.profile.mobile);
					forwardTo('/checkout', {
						giftVoucher: Basket.getDeliveryOption().id === 'gift-vouchers' ? true : false,
					});
				} else {
					forwardTo('/contact-details', { email, mobile });
				}
			}
		}
	};

	handleNativeSubmit = (allergens = []) => {
		const { email, mobile } = this.state;
		const force_allergen = getConfig()?.flags ? getConfig().flags.force_allergen : false;
		if (!isMinimumOrderTotalSatisfied()) {
			isMinimumOrderTotalSatisfied(true);
			return;
		}
		if (allergens.length > 0 && force_allergen) {
			this.setState({ allergenModal: { isOpen: true, fromCheckoutBtn: true } });
			return;
		}
		Basket.setCustomFieldValue({
			field_value: this.state.customFieldValue,
			field_name: this.state.customFieldName,
		});
		const uuid = `${this.props.profile.id}_${Date.now()}`;
		Basket.setUUID(uuid);
		if (Basket.getOrderType() === 'Table') {
			const tableRequiredFields = getConfig().general.tableRequiredFields || [];
			if (tableRequiredFields?.length === 0 || !tableRequiredFields) {
				this.props.auth.loggedIn ? Basket.createOrder() : forwardTo('/contact-details', { email, mobile });
			} else {
				let valid = true;
				for (let i = 0; i < tableRequiredFields.length; i++) {
					if (!this.props.profile[`${tableRequiredFields[i]}`]) {
						valid = false;
						break;
					}
				}
				!valid ? forwardTo('/contact-details', { email, mobile }) : Basket.createOrder('NativePayment');
			}
		} else {
			if (this.props.profile.mobile && this.props.profile.first_name) {
				Basket.createOrder('NativePayment');
			} else {
				forwardTo('/contact-details', { email, mobile });
			}
		}
	};

	handleOrderItemClick = (item, index, quantity, restaurant) => {
		changeItemQuantity(index, quantity, false, restaurant);
		// this.setState({ quantityModal: { item, index } });
	};
	updateItemQuantity = () => {
		const { item, index } = this.state.quantityModal;
		if (this.state.quantityModal) {
			changeItemQuantity(index, item.quantity);
			this.setState({ quantityModal: null });
		}
	};

	onIncrementerUpdate = (newQuantity) => {
		this.setState({
			quantityModal: {
				...this.state.quantityModal,
				item: {
					...this.state.quantityModal,
					quantity: newQuantity,
				},
			},
		});
	};
	cancelTaxCalculation = () => {
		const { dispatch } = this.props;
		dispatch(setCommonProp('taxCalculated', false));
		dispatch(setCommonProp('taxContentLoading', false));
		dispatch(setCommonProp('taxCanceled', true));
	};
	showDriverModal = (modalOpen) => {
		this.setState({ driverModal: modalOpen });
	};
	showCollectedModal = (modalOpen) => {
		this.setState({ collectedModal: modalOpen });
	};
	formatDayName = (__, name) => {
		if (name.includes('Today')) {
			name = 'Today'.toLowerCase();
		} else if (name.includes('Tomorrow')) {
			name = 'Tomorrow'.toLowerCase();
		} else {
			name = getOrderDate();
		}
		return `${__(name)}`;
	};
	isRedeemPointsDisabled = () => {
		const { redeemPointsMin } = getConfig().general;
		const { available_balance } = this.props.profile;
		return available_balance < redeemPointsMin ? true : false;
	};

	findSelectedSlot = (dayInWeek, time) => {
		const restaurant = Basket.getRestaurant();
		const selectedDay =
			!isEmptyObject(restaurant) &&
				restaurant.delivery_times_json &&
				!isEmptyObject(restaurant.delivery_times_json) &&
				restaurant.delivery_times_json.slots &&
				!isEmptyObject(restaurant.delivery_times_json.slots)
				? restaurant.delivery_times_json.slots[dayInWeek]
				: null;
		if (selectedDay) {
			const selectedSlot = selectedDay.find((day) => moment(day.start_time, 'HH:mm').format('HH:mm') === moment(time, 'HH:mm a').format('HH:mm'));
			return moment(selectedSlot.start_time, 'HH:mm').format('h:mm a') + ' - ' + moment(selectedSlot.end_time, 'HH:mm').format('h:mm a');
		}
		return null;
	};
	getTax() {
		const uuid = `${this.props.profile.id ?? 'guest'}_${Date.now()}`;
		Basket.setUUID(uuid, true);
		if (Basket._getServiceCharge() === 0 && getConfig().general.service_charge_modules?.includes(Basket.order_type) && getConfig().flags.hasServiceChargeAlertPrompt) {
			this.props.dispatch(setCommonProp('serviceChargeBoxAlertOpen', true));
		} else {
			let data = Basket.has_multi_basket
				? deepCopy(Basket.multi_basket_items)
				: [
					{
						restaurant: { business_location_id: Basket.getRestaurant()?.business_location_id },
						items: Basket.items,
					},
				];
			const additionalData = Basket.getAdditionalTaxData();
			if (additionalData) {
				data.push(additionalData);
			}
			this.props.dispatch({ type: GET_TAX, data, includeAll: true });
		}
	}
	drawContentHeader = (__, deliveryOption, orderType, deliveryAddress) => {
		let dateName = this.formatDayName(
			__,
			moment(Basket.getOrderDate(), 'dddd Do MMMM').calendar(null, {
				sameDay: '[Today]',
				nextDay: '[Tomorrow]',
			}),
		);
		const selectedRestaurant = Basket.getRestaurant();
		if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'pick-up-point') {
			return (
				<div className={`box-content order-header-content ${Basket.has_multi_basket ? 'multi-order-heder-content' : ''}`}>
					<div>
						<LocationIcon />
						<StrongText>{Basket.getRestauranName()}</StrongText>
					</div>
				</div>
			);
		} else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'charter-delivery' && !isEmptyObject(deliveryAddress)) {
			return (
				<div className={`box-content order-header-content ${Basket.has_multi_basket ? 'multi-order-heder-content' : ''}`}>
					<div>
						<LocationIcon />
						<StrongText>{Basket.getRestauranName()}</StrongText>
					</div>
					{this.props.driverBookingPending ? (
						<div>
							<div>
								<div>
									<NormalText>{__('Booking delivery...')}</NormalText>
								</div>
								<StrongText>{__('Allocating an available driver for your order...')}</StrongText>
							</div>
							<div>
								<IonSpinner name={'circular'} style={{ height: '30px', width: '30px' }} />
							</div>
						</div>
					) : (
						<>
							{this.props.driverBookingMessage ? (
								<div>
									<div>
										<div>
											<NormalText>{__('Booking delivery...')}</NormalText>
										</div>
										<StrongText>{__('Driver successfully booked')}</StrongText>
									</div>
									<div style={{ display: 'flex' }}>
										<IonIcon style={{ height: '35px', width: '35px' }} color="success" icon={checkmarkCircleOutline} />
									</div>
								</div>
							) : (
								<>
									<div>
										<NormalText>{__('Delivery to')}</NormalText>
									</div>
									<NormalText>
										{deliveryAddress.addressLine1}, {deliveryAddress.place} {deliveryAddress.postalCode} {dateName} {__('at')} {Basket.getOrderTime()}
									</NormalText>
								</>
							)}
						</>
					)}
				</div>
			);
		} else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'table') {
			return (
				<>
					<div style={{ marginBottom: 'var(--ion-padding)' }}>
						<StrongText className='text-to-update'>{__('Order to table details')}</StrongText>
					</div>

					<div className={`box-content order-header-content ${Basket.has_multi_basket ? 'multi-order-heder-content' : ''}`}>
						<div>
							<LocationIcon />{' '}
							<StrongText>{Basket.getRestauranName()}</StrongText>
						</div>
						<div>
							<TableIcon />{' '}

							<NormalText>{__('Table number')}</NormalText>
							<StrongText className="bold">{Basket.getTableNumber()}</StrongText>
						</div>
					</div>
				</>

			);
		} else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'scheduled-collection') {
			return (
				<div className={`box-content order-header-content ${Basket.has_multi_basket ? 'multi-order-heder-content' : ''}`}>
					<div>
						<LocationIcon />{' '}
						<StrongText>{Basket.getRestauranName()}</StrongText>
					</div>
					<div>
						<TimeIcon />{' '}

						<NormalText>{__('Collection time')}</NormalText>
					</div>
					<NormalText className="bold">
						{Basket.additional_data?.named_time_slots_data ? (
							<>
								{dateName} {'at'} {Basket.additional_data?.named_time_slots_data}
							</>
						) : (
							<>
								{dateName} {'at'} {Basket.getOrderTime('h:mm a')}
							</>
						)}
					</NormalText>
				</div>
			);
		} else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'pick-up-at-counter') {
			return (
				<div className={`box-content order-header-content ${Basket.has_multi_basket ? 'multi-order-heder-content' : ''}`}>
					<div>
						<LocationIcon />{' '}
						<StrongText>{Basket.getRestauranName()}</StrongText>
					</div>
					<div>
						<TimeIcon />{' '}
						<NormalText>{__('Collection time')}</NormalText>
						<NormalText className="bold">{__('ASAP')}</NormalText>
					</div>
				</div>
			);
		} else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'browse-menu' && selectedRestaurant) {
			return (
				<div className={`box-content order-header-content ${Basket.has_multi_basket ? 'multi-order-heder-content' : ''}`}>
					<div>
						<LocationIcon />{' '}

						<StrongText >{selectedRestaurant.name} &nbsp;</StrongText>
						{selectedRestaurant.browse_menu_json_time_selector > 1 ? (
							<div onClick={() => forwardTo(`/browse-menu?bl_id=${selectedRestaurant.business_location_id}`)}>
								<NormalText className="bold link underlined">{`${__('View other menus')}`}</NormalText>
							</div>
						) : null}
					</div>
					<div>
						<NormalText className="block">{__(selectedRestaurant.address)}</NormalText>
					</div>
					{selectedRestaurant?.distance && (
						<div className="distance-wrapper">
							<IonIcon className="location-icon" icon={location}></IonIcon>
							<NormalText className="block bold-description click-collect-small-description">
								{selectedRestaurant.distance < 1 ? selectedRestaurant.distance.toFixed(1) : selectedRestaurant.distance.toFixed(0)} {__(getDistanceUnit())}
							</NormalText>
						</div>
					)}
				</div>
			);
		} else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'scheduled-delivery') {
			return (
				<div className="box-content order-header-content">
					<div>
						<LocationIcon />{' '}

						<StrongText className="bold">{Basket.getRestauranName()}</StrongText>
					</div>
					<div>
						<NormalText>{__('Delivery to')}</NormalText>
					</div>
					<NormalText className="block">
						{deliveryAddress.addressLine1}, {deliveryAddress.place} {deliveryAddress.postalCode}
					</NormalText>
					<NormalText className="block">
						{' '}
						{dateName} {__('at')} {Basket.getOrderTime('h:mm a')}
					</NormalText>
				</div>
			);
		} else {
			return (
				<>
					<div style={{ marginBottom: 'var(--ion-padding)' }}>
						<StrongText className='text-to-update'>{__('Collection details')}</StrongText>
					</div>
					<div className={`box-content order-header-content ${Basket.has_multi_basket ? 'multi-order-heder-content' : ''}`}>
						<div>
							<LocationIcon />

							<StrongText>{Basket.getRestauranName()}</StrongText>
							<EditIcon style={{ margin: 0 }} onClick={() => forwardTo('/click-and-collect', { saveBasket: true })} />

						</div>
						<div>
							<TimeIcon />{' '}

							<NormalText>{__('Collection time')}</NormalText>
							<NormalText className="bold">{Basket.getOrderTime('h:mm a')}</NormalText>
						</div>
					</div>
				</>
			);
		}
	};
	handleApplyModal = (flag) => {
		const { history, auth } = this.props;
		const isAuth = auth.loggedIn;
		if (!isAuth) {
			this.props.dispatch(setProtectedReferrer(history.location.path));
			this.props.dispatch(restoreAuth());
			forwardTo('/login');
		} else {
			if (isWebConfig()) {
				this.setState({ applyPointsModalOpen: flag });
			} else {
				forwardTo('/apply-points');
			}
		}
	};
	handleApplyLoyaltyModal = () => {
		const { history, auth } = this.props;
		const isAuth = auth.loggedIn;
		if (!isAuth) {
			this.props.dispatch(setProtectedReferrer(history.location.path));
			this.props.dispatch(restoreAuth());
			forwardTo('/login');
		} else {
			forwardTo('/apply-loyalty');
		}
	};
	formatTableNumberForSelect = (selectedRestaurantId) => {
		const { __ } = this.props;
		let arrForSelect = [];
		if (selectedRestaurantId) {
			(this.state.totalTableNumbers || []).map((table, index) => {
				arrForSelect.push({ text: table, value: table });
			});
		}
		return [{ text: __('Select table'), value: null }, ...arrForSelect];
	};
	getCustomFieldForOrderType = () => {
		const custom_field = Basket.getRestaurant()?.custom_field;
		let result = false;
		switch (Basket.order_type) {
			case 'collection':
				result = custom_field?.['collection'];
				break;
			case 'scheduled-collection':
				result = custom_field?.['scheduled-collection'];
				break;
			case 'scheduled-delivery':
				result = custom_field?.['scheduled-delivery'];
				break;
			case 'table':
				result = custom_field?.['table'];
				break;
			case 'charter-delivery':
				result = custom_field?.['charter-delivery'];
				break;
			case 'pick-up-at-counter':
				result = custom_field?.['pick_up_at_counter'];
				break;
		}
		if (isEmptyObject(result)) {
			return false;
		} else {
			return result;
		}
	};
	handlerOfVoucherCode = (e) => {
		const val = e.target.value.toUpperCase();
		this.setState({ voucherCode: val });
	};
	sendCode = () => {
		const { dispatch } = this.props;
		const { voucherCode } = this.state;
		const data = { code: voucherCode.toUpperCase() };
		this.setState({ voucherCode: null });
		dispatch(sendVoucherCode(data, false, true));
	};
	setMobileCode = (mobile_code) => {
		this.setState({ mobile_code, mobile: `${mobile_code}${this.state.mobile_value}` });
		Basket.setMobile(`${mobile_code}${this.state.mobile_value}`);
	};
	setMobileValue = (mobile_value) => {
		this.setState({ mobile_value, mobile: `${this.state.mobile_code}${mobile_value}` });
		Basket.setMobile(`${this.state.mobile_code}${mobile_value}`);
	};
	toggleGiftCards = (param) => {
		this.setState((prevState) => {
			const { openedGiftCards } = prevState;
			const index = openedGiftCards.indexOf(param);
			let newArray;

			if (index === -1) {
				newArray = [...openedGiftCards, param];
			} else {
				newArray = openedGiftCards.filter((item) => item !== param);
			}
			return { openedGiftCards: newArray };
		});
	};
	onApplyCardValue = (newValue, card) => {
		let appliedGiftCards = Basket.getAppliedGiftCards();
		const selectedCard = appliedGiftCards.find((el) => el.id == card.id);
		if (selectedCard) {
			selectedCard.value = newValue;
		} else {
			appliedGiftCards.push({ id: card.id, value: newValue });
		}
		Basket.setAppliedGiftCards(appliedGiftCards);
	};
	changeTableNumber = () => {
		const tablePlan = getConfig().tablePlan || [];
		const tableData = tablePlan.map((zone) => zone.tables.map((table) => ({ value: table.id, text: `Table ${table.table_number}`, group: zone.name }))).flat();
		this.setState({
			changeTableModal: true,
			tableData,
		});
	};
	render() {
		const { __, profile, auth, dispatch, clientSecret, applicableVouchers, cards, history, giftCards } = this.props;
		const { quantityModal, allergenModal, applyPointsModalOpen, mobile, email, mobile_code, mobile_value, showDiscount, canAppleORGooglePay, voucherCode, applyPointsVisible, applyVoucherCodeVisible } = this.state;
		const valid = validateProfileData(profile).isValid;
		const allergens = getAllergen() || [];
		const isAuth = auth.loggedIn;
		const customFieldData = this.getCustomFieldForOrderType();
		const isGuest = isEmptyObject(profile) || profile?.is_guest;
		const numberOfApplicableVouchers = applicableVouchers?.length || 0;
		const applicableVouchersText = numberOfApplicableVouchers > 1 ? `${numberOfApplicableVouchers} ${__('vouchers available')}` : `${numberOfApplicableVouchers} ${__('voucher available')}`;
		const mobileRequired = isAuth ? Basket.getOrderType() !== 'Table' || (getConfig().general.tableRequiredFields || []).filter((el) => el === 'mobile').length > 0 : false;
		const points_value_percentage = isDefined(getConfig().api_config) && isDefined(getConfig().api_config.points_value_percentage) ? getConfig().api_config.points_value_percentage : 0;
		return (
			<>
				<div className="absolute-content flex-row-wrapper summary-wrapper scrollable-y checkout">
					{Basket.getDeliveryOption().id !== 'gift-vouchers' ? (
						<>
							<div className="checkout">
								{this.drawContentHeader(__, Basket.getDeliveryOption(), Basket.getOrderType(), Basket.getDeliveryAddress())}
								<Spacer size={1} />
								{isAuth && (
									<>
										{getConfig().flags.hasMobileCountryCode ? (
											<div className="input-field-container">
												<StrongText>{__('Contact Number')}</StrongText>

												<MobileInput mobile_code={mobile_code} mobile_value={mobile_value} setMobileCode={this.setMobileCode} setMobileValue={this.setMobileValue} />
												{this.state.mobileError && <FieldError className="field-error" value={__(this.state.mobileError)} />}

											</div>

										) : (
											<div className="input-field-container">
												<StrongText>{__('Contact Number')}</StrongText>
												<IonItem lines="none" className="input-field-wrapper">
													<IonInput
														className="mobile-field"
														onIonChange={(e) => this.handleInput('mobile', e.target.value)}
														clearInput
														required={true}
														type="tel"
														pattern="tel"
														inputmode="tel"
														onKeyPress={(e) => {
															const reqexFormat = mobile ? '^[0-9]+$' : '^[+0-9]+$';
															let regex = new RegExp(reqexFormat);
															if (regex.test(e.key)) {
																return true;
															}
															e.preventDefault();
															return false;
														}}
														value={mobile}
													>
														{mobile === '' ? <IonIcon icon={pencilOutline} className="contact-number-icon" /> : null}
													</IonInput>
												</IonItem>
												{this.state.mobileError && <FieldError className="field-error" value={__(this.state.mobileError)} />}

											</div>
										)}
									</>
								)}

								{Basket.getDeliveryOption().id === 'pick-up-at-counter' && (
									<>
										<div className="input-field-container ">
											<StrongText>{__('Enter your email address')}</StrongText>
											<IonItem lines="none" className="input-field-wrapper">
												<IonInput
													value={email}
													clearInput={true}
													disabled={!isGuest}
													type="email"
													pattern="email"
													inputmode="email"
													onIonChange={(e) => this.setState({ email: e.target.value })}
												>
													{email === '' ? <IonIcon icon={pencilOutline} className="contact-number-icon" /> : null}
												</IonInput>
											</IonItem>
										</div>
									</>
								)}
								{customFieldData && (
									<>
										<div className="input-field-container ">
											<StrongText>{__(customFieldData.field_name)}</StrongText>
											<IonItem lines="none" className="input-field-wrapper">
												<IonInput
													value={this.state.customFieldValue}
													onIonChange={(e) =>
														this.setState({
															customFieldValue: e.target.value,
															customFieldName: customFieldData.field_name,
														})
													}
												></IonInput>
											</IonItem>
										</div>
										{this.state.customFieldError && <FieldError className="field-error" value={__('Required field')} />}
										<Spacer size={1} />
									</>
								)}

								{itemsCount() > 0 ? (
									<OrderContent showAddItems={true} handleOrderItemClick={this.handleOrderItemClick.bind(this)} />
								) : (
									<>
										<IonItem lines="none">
											<div className="sectiontitle" style={{ width: '100vh', textAlign: 'center' }}>
												{__('No items')}
											</div>
										</IonItem>
										<IonButton color="primary" expand="block" onClick={() => forwardTo('/order')}>
											{__('Add more items')}
										</IonButton>
									</>
								)}
								<Spacer size={1} />
								<div className="order-summary-additional-buttons-wrapper">


									{Basket.getDeliveryOption().id !== 'gift-vouchers' && !getConfig().flags.removeRedeemLoyaltyOnCheckout && (
										<>
											<StrongText className='text-to-update'>{__('Discount & loyalty')}</StrongText>
											{!getConfig().flags.removeVouchersFromBasket && (
												<IonButton
													onClick={() => {
														!isAuth
															? forwardTo('/login')
															: itemsCount() === 0 || !valid
																? dispatch(setModal('isVerfiedModalOpen', true))
																: this.handleApplyLoyaltyModal(true);
													}}
													className={`${itemsCount() === 0 || !valid ? 'disabled' : ''} order-summary-additional-button`}
													expand="block"
													color="primary"
													fill="clear"
													disabled={Basket.getAppliedGiftCardsValue() > 0 || Basket.getAppliedPoints() > 0 || Basket.getAppliedVoucher().length > 0 || itemsCount() == 0}
												>
													{getConfig().general?.basketConfig?.availableVouchersIcon ? <img src={getConfig().general.basketConfig.availableVouchersIcon} /> : <DiscountIcon />}

													<NormalText>{`${this.props.vouchers.length} ${this.props.vouchers.length == 1 ? __('available voucher') : __('available vouchers')}`}</NormalText>
													<IonIcon icon={chevronForwardOutline}></IonIcon>
												</IonButton>
											)}
											{getConfig().flags.hasApplyVoucherCodeOnCheckout && (
												<div className="order-summary-points-wrapper ">
													<IonButton
														onClick={() => {
															this.setState({ applyVoucherCodeVisible: !applyVoucherCodeVisible }, () => {
																if (document.querySelector('.redeem-voucher-field-wrapper')?.parentElement &&
																	!isElementFullyVisible(document.querySelector('.redeem-voucher-field-wrapper')?.parentElement)) {
																	document.querySelector('.redeem-voucher-field-wrapper')?.parentElement?.scrollIntoView({ behavior: "smooth", block: "end" })
																	setTimeout(() => {
																		this.voucherCodeInput?.current?.setFocus()
																	}, 200);
																}
															})
														}}
														expand="block"
														color="primary"
														fill="clear"
														className='order-summary-additional-button'>
														{getConfig().general?.basketConfig?.redeemVouchersIcon ? <img src={getConfig().general.basketConfig.redeemVouchersIcon} /> : <DiscountIcon />}

														<NormalText>{__('Apply voucher code')}</NormalText>
														<IonIcon icon={applyVoucherCodeVisible ? closeOutline : chevronForwardOutline}></IonIcon>
													</IonButton>

													{applyVoucherCodeVisible &&
														<div >
															<div className="redeem-voucher-field-wrapper">
																<div className="input-field-container">
																	<IonItem className="input-field-wrapper" lines="none">
																		<IonInput ref={this.voucherCodeInput} placeholder={__('Voucher Code')} onInput={(e) => this.handlerOfVoucherCode(e)}></IonInput>
																	</IonItem>
																</div>
																<IonButton onClick={this.sendCode} disabled={!voucherCode} color="primary" expand="block" className="submit-button">
																	{__('Submit')}
																</IonButton>
															</div>
														</div>
													}

												</div>
											)}
											{profile.available_balance > 0 && (
												<div className="order-summary-points-wrapper">
													<IonButton
														onClick={() => {
															!isAuth
																? forwardTo('/login')
																: itemsCount() === 0 || !valid
																	? dispatch(setModal('isVerfiedModalOpen', true))
																	: this.setState({ applyPointsVisible: !applyPointsVisible }, () => {
																		if (this.state.applyPointsVisible) {
																			document.querySelector('.points-redeem-box-wrapper').scrollIntoView();
																		}
																	});
														}}
														className={`${itemsCount() === 0 || !valid ? 'disabled' : ''} order-summary-additional-button`}
														expand="block"
														color="primary"
														fill="clear"
														disabled={Basket.getAppliedGiftCardsValue() > 0 || Basket.getAppliedVoucher().length > 0 || itemsCount() == 0}
													>
														<NormalText>{__('Spend loyalty points')}</NormalText>
														<IonIcon icon={applyPointsVisible ? closeOutline : chevronForwardOutline}></IonIcon>
													</IonButton>
													{applyPointsVisible && <PointsReedemBox />}
												</div>
											)}
											{giftCards.length > 0 && (
												<div className="order-summary-cards-wrapper">
													{giftCards.map((card, index) => {
														const cardPoints = card.value_remaining;
														const basketTotalInCents = new BigNumber(Basket.getTotal()).times(100).toNumber();
														const quantity = Basket.getAppliedGiftCards().find((el) => el.id === card.id)?.value || 0;
														const maxLimit = Math.min(basketTotalInCents, cardPoints) / 100 - quantity;
														const validMaxLimit = maxLimit > 0 ? maxLimit : 0;
														const opened = this.state.openedGiftCards.includes(index);
														return (
															<>
																<IonButton
																	onClick={() => this.toggleGiftCards(index)}
																	className={`order-summary-additional-button ${opened ? 'order-summary-additional-button-opened' : ''}`}
																	expand="block"
																	color="primary"
																	fill="clear"
																	disabled={Basket.getAppliedPoints() > 0 || Basket.getAppliedVoucher().length > 0 || itemsCount() === 0}
																>
																	<NormalText>{`${__('Gift card')} #${card.id}`}</NormalText>
																	<IonIcon icon={opened ? closeOutline : chevronForwardOutline}></IonIcon>
																</IonButton>
																{opened && (
																	<div className="gift-card-wrapper">
																		<div>
																			<NormalText>{`${__('Available balance')} ${Basket.formatPrice(cardPoints / 100, true)}`}</NormalText>
																			<IonButton onClick={() => this.onApplyCardValue(validMaxLimit + quantity, card)} className="link underlined">
																				{__('apply max')}
																			</IonButton>
																		</div>
																		<div className="item-content-actions">
																			<Incrementer
																				maxLimit={quantity + validMaxLimit}
																				onUpdate={this.onApplyCardValue}
																				data={card}
																				quantity={quantity}
																				allowNegative={false}
																				step={50}
																				minLimit={0}
																			>
																				{Basket.formatPrice(quantity, true)}
																			</Incrementer>
																		</div>
																	</div>
																)}
															</>
														);
													})}
												</div>
											)}
										</>
									)}
									{allergens.length > 0 ? (
										<IonButton
											fill="clear"
											expand="block"
											className="order-summary-additional-button"
											color="dark"
											onClick={() => this.setState({ allergenModal: { isOpen: true, fromCheckoutBtn: false } })}
										>
											<NormalText> {__('View allergen information')}</NormalText>
											<IonIcon icon={chevronForwardOutline}></IonIcon>
										</IonButton>
									) : null}
								</div>
							</div>
							<Spacer size={1} />
						</>
					) : (
						<div>
							<Title>{__('eGift Voucher Summary')}</Title>
							<Spacer size={1} />
							<div className="gift-voucher-order-content-summary box-content" style={{ margin: 0 }}>
								<Subtitle className="bold">
									{Basket.formatPrice(this.props.giftVoucherData?.points_value / 100)} {__('eGift Voucher')}
								</Subtitle>
								<NormalText>
									{__('Recipient')}
									{': '}
									{this.props.giftVoucherData?.recipient_email}
								</NormalText>
								{this.props.giftVoucherData?.send_on_date && (
									<NormalText className="block">
										{__('Send date')}
										{': '}
										{moment(this.props.giftVoucherData?.send_on_date).format('Do MMM yyyy')}
									</NormalText>
								)}
							</div>
							<Spacer size={1} />

							<OrderContent showAddItems={false} />
						</div>
					)}
					<div className="flex-min pay-buttons-wrapper">
						{getConfig().frenchDisclaimer?.disclaimerImage && (
							<div className="disclaimer-wrapper">
								<img src={getConfig().frenchDisclaimer?.disclaimerImage}></img>
							</div>
						)}
						{!getConfig().flags.paymentDisabled && auth.loggedIn && (<>
							{isWeb() ? (
								<div className='pay-buttons-wrapper'>
									{!canAppleORGooglePay && cards.length === 0 ? null : (<Subtitle className="bold">{__('Payment')}</Subtitle>)}
									<Spacer size={1} />
									{isAuth && isMinimumOrderTotalSatisfied() ? (
										<CheckoutPay isBillPay={false} clientSecret={clientSecret} dispatch={dispatch} __={__} location={location} profile={profile} canMakePayment={canAppleORGooglePay} showBlockSeparator={true} />
									) : null}
									<CardList />
								</div>
							) : null
							}
							{isWeb() && cards.length > 0 ? (
								<IonButton
									disabled={!isMinimumOrderTotalSatisfied() || itemsCount() === 0 || cards.length === 0 || !getSelectedCard()}
									onClick={() => {
										let customFieldError = false;
										let mobileError = false;
										if (customFieldData && customFieldData.is_required && this.state.customFieldValue == '') {
											this.setState({ customFieldError: true });
											customFieldError = true;
										} else {
											this.setState({ customFieldError: false });
											customFieldError = false;
										}
										if (mobileRequired && (!mobile || mobile_code == mobile)) {
											this.setState({ mobileError: 'Required field' });
											mobileError = true;
										} else if (getConfig().flags.hasMobileCountryCode && mobileRequired && !/^[0-9]+$/.test(mobile_value)) {
											this.setState({ mobileError: 'Invalid mobile number' });
											mobileError = true;
										} else {
											this.setState({ mobileError: false });
											mobileError = false;
										}
										if (!mobileError && !customFieldError) {
											!this.props.location?.state?.fromTablePay ? this.handleOrderCreation(() => Basket.createOrder()) : Basket.createBillPay();
										}
									}}
									expand="block"
									className='checkout-btn large-button'
								>
									{__('Pay with selected card')}
								</IonButton>
							) : !isWeb() && getConfig().nativePayment?.isVisible ? (
								<IonButton
									disabled={!isMinimumOrderTotalSatisfied() || itemsCount() === 0}
									style={{ marginBottom: '10px' }}
									className="uppercase okx-font-secondary"
									onClick={() => {
										let customFieldError = false;
										let mobileError = false;
										if (customFieldData && customFieldData.is_required && this.state.customFieldValue == '') {
											this.setState({ customFieldError: true });
											customFieldError = true;
										} else {
											this.setState({ customFieldError: false });
											customFieldError = false;
										}
										if (mobileRequired && (!mobile || mobile_code == mobile)) {
											this.setState({ mobileError: 'Required field' });
											mobileError = true;
										} else if (getConfig().flags.hasMobileCountryCode && mobileRequired && !/^[0-9]+$/.test(mobile_value)) {
											this.setState({ mobileError: 'Invalid mobile number' });
											mobileError = true;
										} else {
											this.setState({ mobileError: false });
											mobileError = false;
										}
										if (!mobileError && !customFieldError) {
											this.handleOrderCreation(() => this.handleNativeSubmit())

										}
									}}
									expand="block"
								>
									{__(getConfig().nativePayment?.label)}
								</IonButton>
							) : null}

							{isWeb() && cards.length === 0 ? (
								<IonButton
									className='large-button'
									color="primary"
									expand="block"
									disabled={!isMinimumOrderTotalSatisfied()}
									onClick={() => {
										let customFieldError = false;
										let mobileError = false;
										if (customFieldData && customFieldData.is_required && this.state.customFieldValue == '') {
											this.setState({ customFieldError: true });
											customFieldError = true;
										} else {
											this.setState({ customFieldError: false });
											customFieldError = false;
										}
										if (mobileRequired && (!mobile || mobile_code == mobile)) {
											this.setState({ mobileError: 'Required field' });
											mobileError = true;
										} else if (getConfig().flags.hasMobileCountryCode && mobileRequired && !/^[0-9]+$/.test(mobile_value)) {
											this.setState({ mobileError: 'Invalid mobile number' });
											mobileError = true;
										} else {
											this.setState({ mobileError: false });
											mobileError = false;
										}
										if (!mobileError && !customFieldError) {
											if (getTotal() == 0) {
												this.handleOrderCreation(() => Basket.createOrder())

											} else {
												forwardTo('/card-add', { ...(this.props.location && this.props.location.state ? this.props.location.state : {}), addCardFromCheckout: true })
											}
										}
									}}
								>
									{`${__('Pay')} ${Basket.formatPrice(Basket.getTotal())}`}
								</IonButton>
							) : isWeb() ? (
								<IonButton
									fill="clear"
									color='secondary'
									disabled={!isMinimumOrderTotalSatisfied()}
									className="link underlined centered"
									onClick={() =>
										forwardTo('/card-add', { ...(this.props.location && this.props.location.state ? this.props.location.state : {}), addCardFromCheckout: true })
									}
								>
									{__((cards.length > 0 ? 'Or add another' : 'Add ') + ' payment card')}
								</IonButton>
							) : null
							}</>)}
						{Basket.getTaxBreakdown().length > 0 || this.props.taxCalculated || !getConfig().flags.hasTaxBreakdown ? (<>
							{isGuest ? <IonButton
								className='large-button'
								color="primary"
								expand="block"
								disabled={!isMinimumOrderTotalSatisfied()}
								onClick={() => {
									let customFieldError = false;
									let mobileError = false;
									if (customFieldData && customFieldData.is_required && this.state.customFieldValue == '') {
										this.setState({ customFieldError: true });
										customFieldError = true;
									} else {
										this.setState({ customFieldError: false });
										customFieldError = false;
									}
									if (mobileRequired && (!mobile || mobile_code == mobile)) {
										this.setState({ mobileError: 'Required field' });
										mobileError = true;
									} else if (getConfig().flags.hasMobileCountryCode && mobileRequired && !/^[0-9]+$/.test(mobile_value)) {
										this.setState({ mobileError: 'Invalid mobile number' });
										mobileError = true;
									} else {
										this.setState({ mobileError: false });
										mobileError = false;
									}
									if (!mobileError && !customFieldError) {
										this.handleSubmit(allergens);
									}
								}}
							>
								{`${__('Pay')} ${Basket.formatPrice(Basket.getTotal())}`}
							</IonButton> : <>
								{getConfig().flags.hasYocoPayment && (

									<IonButton

										disabled={itemsCount() === 0 || !isMinimumOrderTotalSatisfied()}
										style={{ marginBottom: '10px' }}
										className='large-button'
										onClick={() => {
											let customFieldError = false;
											let mobileError = false;
											if (customFieldData && customFieldData.is_required && this.state.customFieldValue == '') {
												this.setState({ customFieldError: true });
												customFieldError = true;
											} else {
												this.setState({ customFieldError: false });
												customFieldError = false;
											}
											if (mobileRequired && (!mobile || mobile_code == mobile)) {
												this.setState({ mobileError: 'Required field' });
												mobileError = true;
											} else if (getConfig().flags.hasMobileCountryCode && mobileRequired && !/^[0-9]+$/.test(mobile_value)) {
												this.setState({ mobileError: 'Invalid mobile number' });
												mobileError = true;
											} else {
												this.setState({ mobileError: false });
												mobileError = false;
											}
											if (!mobileError && !customFieldError) {
												if (this.props.auth.loggedIn) {
													this.handleYocoSubmit(allergens);
												} else {
													forwardTo('/login');
												}
											}
										}}
										expand="block"
									>
										{__('Pay With Yoco')}
									</IonButton>
								)}
								{(isWeb() || getConfig()?.prebuildPayment?.showOnNative) && getConfig().prebuildPayment?.isVisible ? (
									<IonButton
										disabled={!isMinimumOrderTotalSatisfied()}
										style={{ marginBottom: '10px' }}
										className='large-button'
										onClick={() => {
											let customFieldError = false;
											let mobileError = false;
											if (customFieldData && customFieldData.is_required && this.state.customFieldValue == '') {
												this.setState({ customFieldError: true });
												customFieldError = true;
											} else {
												this.setState({ customFieldError: false });
												customFieldError = false;
											}
											if (mobileRequired && (!mobile || mobile_code == mobile)) {
												this.setState({ mobileError: 'Required field' });
												mobileError = true;
											} else if (getConfig().flags.hasMobileCountryCode && mobileRequired && !/^[0-9]+$/.test(mobile_value)) {
												this.setState({ mobileError: 'Invalid mobile number' });
												mobileError = true;
											} else {
												this.setState({ mobileError: false });
												mobileError = false;
											}
											if (!mobileError && !customFieldError) {
												this.handleOrderCreation(() => Basket.createStripeOrder(getConfig().prebuildPayment?.label))

											}
										}}
										expand="block"
									>
										{__(getConfig().prebuildPayment?.label)}
									</IonButton>
								) : null}
								{getConfig().flags.payOnCollection && (Basket.getDeliveryOption()?.id == 'scheduled-collection' || Basket.getDeliveryOption()?.id == 'collection') ? (
									<IonButton
										className='large-button'
										disabled={itemsCount() === 0 || !isMinimumOrderTotalSatisfied()}
										onClick={() => {
											let customFieldError = false;
											let mobileError = false;
											if (customFieldData && customFieldData.is_required && this.state.customFieldValue == '') {
												this.setState({ customFieldError: true });
												customFieldError = true;
											} else {
												this.setState({ customFieldError: false });
												customFieldError = false;
											}
											if (mobileRequired && (!mobile || mobile_code == mobile)) {
												this.setState({ mobileError: 'Required field' });
												mobileError = true;
											} else if (getConfig().flags.hasMobileCountryCode && mobileRequired && !/^[0-9]+$/.test(mobile_value)) {
												this.setState({ mobileError: 'Invalid mobile number' });
												mobileError = true;
											} else {
												this.setState({ mobileError: false });
												mobileError = false;
											}
											if (!mobileError && !customFieldError) {
												this.handleOrderCreation(() => this.showCollectedModal(true))

											}
										}}
										expand="block"
										color="primary"
									>
										{`${__('Pay On Collection')}`}
									</IonButton>
								) : null}

								{getConfig().flags.payTheDriver && (Basket.getDeliveryOption()?.id === 'delivery' || Basket.getDeliveryOption()?.id === 'charter-delivery') ? (
									<IonButton
										className='large-button'
										disabled={itemsCount() === 0 || !isMinimumOrderTotalSatisfied()}
										onClick={() => {
											let customFieldError = false;
											let mobileError = false;
											if (customFieldData && customFieldData.is_required && this.state.customFieldValue == '') {
												this.setState({ customFieldError: true });
												customFieldError = true;
											} else {
												this.setState({ customFieldError: false });
												customFieldError = false;
											}
											if (mobileRequired && (!mobile || mobile_code == mobile)) {
												this.setState({ mobileError: 'Required field' });
												mobileError = true;
											} else if (getConfig().flags.hasMobileCountryCode && mobileRequired && !/^[0-9]+$/.test(mobile_value)) {
												this.setState({ mobileError: 'Invalid mobile number' });
												mobileError = true;
											} else {
												this.setState({ mobileError: false });
												mobileError = false;
											}
											if (!mobileError && !customFieldError) {
												this.handleOrderCreation(() => this.showDriverModal(true))
											}
										}}
										expand="block"
										color="primary"
									>
										{`${__('Pay To The Driver')}`}
									</IonButton>
								) : null}


							</>}

						</>) : (<>
							<IonButton className='large-button' expand="block" onClick={() => this.getTax()}>
								{__('Finalize order')}
							</IonButton>
						</>)}
					</div>
				</div>
				<Modal cssClass="change-table-modal" actionLabel={__('Change')} isOpen={!!this.state.changeTableModal} onDidDismiss={() => this.setState({ changeTableModal: false })}>
					<Title>{__('Change table')}</Title>
					<Spacer size={1} />
					<SelectOption
						inputStyle="box"
						display="inline"
						{...(getConfig().tablePlan && getConfig().tablePlan.length > 1 && { group: { header: true, groupWheel: false, clustered: false } })}
						data={this.state.tableData}
						value={Basket.getTableNumber() || null}
						onSet={(e, a) => Basket.setTableNumber(a.getVal())}
					/>
					<Spacer size={1} />

					<IonButton expand="block" color="primary" onClick={() => this.setState({ changeTableModal: false })}>
						{__('Continue')}
					</IonButton>
				</Modal>
				<Modal
					cssClass="quantity-modal customIncrementModal"
					action={this.updateItemQuantity}
					actionLabel={__('Change')}
					isOpen={!!quantityModal}
					onDidDismiss={() => this.setState({ quantityModal: null })}
					showCancelButton={true}
				>
					<Title>{__('Change quantity')}</Title>
					<Spacer size={1} />
					{quantityModal && quantityModal.item ? <Incrementer allowNegative={false} quantity={quantityModal.item.quantity} onUpdate={this.onIncrementerUpdate} /> : null}
				</Modal>
				<Modal cssClass="allergen-modal" isOpen={allergenModal?.isOpen} onDidDismiss={() => this.setState({ allergenModal: { isOpen: false, fromCheckoutBtn: false } })}>
					<div className="absolute-content flex-row-wrapper">
						<div className="scrollable-y rhino">
							<Title className="">{__('Allergen Information')}</Title>
							<NormalText>{__('Please speak to our staff if you have any concerns about allergies')}</NormalText>
							<Spacer />
							{Basket.has_multi_basket ? (
								<>
									{Basket.multi_basket_items.map((order, orderIndex) => {
										return (
											<>
												{order.items.map((item, index) => {
													let data = allergens.find((allergen) => allergen[1].sku === item.item.sku);
													const modifiers = item?.selectedChoices
														?.flat()
														?.map((modifier) => allergens?.find((allergen) => allergen?.[1]?.sku === modifier?.sku))
														?.map((el) => el?.[0]?.allergens)
														.filter(Boolean);
													const modifierAllergens = modifiers.length > 0 ? modifiers[0] : [];
													if (data && data.length > 0) {
														return (
															<>
																<div className="box-content" key={`allergen-${orderIndex}-${index}`}>
																	<NormalText className="block">
																		{item.quantity}x {Basket.getProductName(item.item, this.props.profile)}
																	</NormalText>
																	<Spacer size={1} />
																	<PillGroup items={[...new Set([...data[0].allergens, ...modifierAllergens])]} borderColor="primary" />
																</div>
																<Spacer size={1} />
															</>
														);
													}

													return null;
												})}
											</>
										);
									})}
								</>
							) : (
								<>
									{getItems().map((item, index) => {
										let data = allergens.find((allergen) => allergen[1].sku === item.item.sku);
										const modifiers = item?.selectedChoices
											?.flat()
											?.map((modifier) => allergens?.find((allergen) => allergen?.[1]?.sku === modifier?.sku))
											?.map((el) => el?.[0]?.allergens)
											.filter(Boolean);
										const modifierAllergens = modifiers.length > 0 ? modifiers[0] : [];
										if (data && data.length > 0) {
											return (
												<>
													<div className="box-content" key={'allergen-' + index}>
														<NormalText className="block">
															{item.quantity}x {Basket.getProductName(item.item, this.props.profile)}
														</NormalText>
														<Spacer size={1} />
														<PillGroup items={[...new Set([...data[0].allergens, ...modifierAllergens])]} borderColor="primary" />
													</div>
													<Spacer size={1} />
												</>
											);
										}

										return null;
									})}
								</>
							)}
						</div>
						<Spacer />
						{allergenModal?.fromCheckoutBtn && (
							<IonButton disabled={itemsCount() === 0} onClick={() => this.handleSubmit([])} expand="block">
								{__('Accept')}
							</IonButton>
						)}
						{allergenModal?.action && (
							<IonButton disabled={itemsCount() === 0} onClick={() => allergenModal.action()} expand="block">
								{__('Accept')}
							</IonButton>
						)}
						<div className="flex-min centered">
							<IonButton fill="clear" className="link underlined" color="secondary" onClick={() => this.setState({ allergenModal: { isOpen: false, fromCheckoutBtn: false } })}>
								{__('Hide allergen information')}
							</IonButton>
						</div>
					</div>
				</Modal>
				<Modal cssClass="apply-points-modal" isOpen={applyPointsModalOpen} onDidDismiss={() => this.handleApplyModal(false)}>
					<ApplyPoints handleApplyModal={this.handleApplyModal} applyPointsModalOpen={applyPointsModalOpen} />
				</Modal>
				<IonAlert
					isOpen={this.state.driverModal}
					onDidDismiss={() => this.showDriverModal(false)}
					header={__('Pay to the Driver')}
					message={__('You agree to pay the driver the amount outstanding on delivery. Earned loyalty will be updated  after payment.')}
					buttons={[
						{
							text: __('Cancel'),
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => this.showDriverModal(false),
						},
						{
							text: __('Confirm'),
							handler: () => {
								Basket.createOrder('driverPay');
								this.showDriverModal(false);
							},
						},
					]}
				/>
				<IonAlert
					isOpen={this.state.collectedModal}
					onDidDismiss={() => this.showCollectedModal(false)}
					header={__('Pay on Collection')}
					message={__('You agree to pay the amount shown before collecting your order. Earned loyalty will be updated after payment.')}
					buttons={[
						{
							text: __('Cancel'),
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => this.showCollectedModal(false),
						},
						{
							text: __('Confirm'),
							handler: () => {
								Basket.createOrder('collectedPay');
								this.showCollectedModal(false);
							},
						},
					]}
				/>
				<IonAlert
					backdropDismiss={false}
					isOpen={this.props.taxContentLoading}
					header={__('Calculating taxes')}
					message={`<div class="tax-alert"><ion-spinner ></ion-spinner> <span class="normal-text">${__('Please wait whilst we calculate taxes')}</span></div>`}
					buttons={[
						{
							text: __('Cancel'),
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => this.cancelTaxCalculation(),
						},
					]}
				/>
			</>
		);
	}
}

const mapStateToProps = (store) => {
	const { basketUpdated, deliveryOption, giftVoucherData } = store.orders;
	const { auth, giftCards } = store.profile;
	return {
		basketUpdated,
		profile: store.profile.profile,
		cards: store.orders.cards || [],
		deliveryOption,
		itemAllergens: store.restaurants.itemAllergens,
		auth,
		giftVoucherData,
		taxCalculated: store.common.taxCalculated,
		taxContentLoading: store.common.taxContentLoading,
		giftCards: giftCards || [],
		vouchers: store.profile.vouchers || []

	};
};

export const OrderSummary = connect(mapStateToProps)(withRouter(withTranslation(OrderSummaryRaw)));

class OrderSummaryWrapped extends React.Component {

	componentDidMount() {
		const { dispatch, profile } = this.props;
		if (!isEmptyObject(profile) && !profile?.is_guest) {
			dispatch(checkDiscount(Basket.parseBasketForCheckVouchers()));
		}
	}

	backHandler = () => {
		if (this.props.location && this.props.location.state && this.props.location.state.fromItemDetails) {
			// skip item details page when we going back from order summary
			go(-2);
		} else if (this.props.location && this.props.location.state && this.props.location.state.skipBackToThePreviousPage) {
			forwardTo('/order');
		} else {
			goBack();
		}
	};
	render() {
		const { __ } = this.props;
		return (
			<Loading transparent>
				<Layout showLoginIcon={getConfig().flags.hasGuestCheckout} color="transparent" title={__('Checkout')} backHandler={this.backHandler} scrollY={false} headerWithTitle={true}>
					<OrderSummary />
				</Layout>
			</Loading>
		);
	}
}

export default connect(mapStateToProps)(withTranslation(OrderSummaryWrapped));
