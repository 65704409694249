import { IonButton, IonCheckbox, IonIcon, IonItem } from '@ionic/react';
import { checkmarkCircleOutline } from 'ionicons/icons';
import React from 'react';
import Basket from '../../lib/basket';
import { NormalText, SmallText, Spacer, StrongText, Subtitle, Title } from '../common';

const { formatPrice } = Basket;

export const SubscriptionLine = (
  __,
  clickHandler,
  key,
  isChecked,
  name,
  description,
  price,
  perks,
) => (
  <>
    <div className="box-content" key={key}>
      <Title className="primary-color">{__(name)}</Title>
      <NormalText className="block">{__(description)}</NormalText>
      <Spacer size={1} />
      <div className="subscription-perks">
        {perks && perks.map((el) => {
          return (
            <div>
              <IonIcon icon={checkmarkCircleOutline} color="primary" />

              <NormalText>{__(el.name)}</NormalText>
            </div>
          );
        })}
      </div>
      <Spacer size={1} />

      <Subtitle className=" block">{`${formatPrice(price, true)} ${__('per month')}`}</Subtitle>
      <SmallText className=" block">{__('Cancel at any time')}</SmallText>

      <Spacer size={1} />
      <IonButton onClick={clickHandler} expand="block" color="primary">
        {__('Select this package')}
      </IonButton>
    </div>
    <Spacer size={1} />
  </>
);
